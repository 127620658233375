.c-core-values {
	background: $secondary;
	color: $paragraph-color;
	font-size: 1.4rem;

	@media screen and (min-width: 461px) {
		font-size: 2rem;
	}

	&__content {
		padding: 60px 0 60px 120px;
		position: relative;

		&:before {
			position: absolute;
			right:100%;
			top: 0;
			content: '';
			display: block;
			background: url(../img/kernwaarden-bg.png) no-repeat top right / cover;
			height: 100%;
			width: 962px;

			@media screen and (max-width: 468px) {
				display: none;
			}
		}

		@media screen and (max-width: 1024px) {
			padding: 60px 0 60px 40px;
		}

		@media screen and (max-width: 460px) {
			padding: 20px 10px;
			h2 {
				font-size: 2.4rem;
				line-height: 3rem;
				margin-bottom: 20px;
			}
		}
	}
}
