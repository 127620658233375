.c-page {
	flex: 1 1 auto;
	padding: 0 0 60px;
	position: relative;
	@include clearfix;

	&__back {
		border-top: 1px solid $tertiary;
		display: block;
		padding: 10px 0;
		text-align: right;

		i {
			@include transition(all 0.3s ease-in-out);
			margin-right: 5px;
		}

		&:hover {
			i {
				margin-right: 10px;
				transform: scale(1.2);
			}
		}
	}

	&--bar:before {
		background: $iron;
		content: "";
		height: 25vh;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
	}

	> div:first-of-type {
		background: white;
		position: relative;
	}

	article:not(.c-widget) {
		padding: 10px;
		position: relative;

		@media screen and (min-width: 480px) { padding: 40px 20px; }

		> figure:first-of-type {
			margin: -40px -20px 20px;

			img {
				height: auto;
				position: relative;
				width: 100%;
				top: 10px;

				@media screen and (min-width: 768px) {
					top: 0;
				}
			}
		}
	}

	aside {
		background: $white;
		padding: 40px;
		position: relative;
		z-index: 1;

		@media print { display: none; }
	}

	h2 {
		font-size: 32px;
		margin-bottom: 0;

		@media screend and (min-width: 768px) {
			margin-bottom: 20px;
		}

		+ hr {
			background: $smoke;
			border: 0;
			height: 1px;
			margin: 0 0 20px;
			width: 100%;
		}

		&.c-title {
			font-weight: 400;
			line-height: 3.2rem;
			text-align: center;
			@include indent-title("large", 2.2rem, $secondary, $secondary);

			@media screen and (min-width: 480px) {
				@include indent-title("large", 3.8rem, $secondary, $secondary);
				line-height: 4.2rem;
			}
		}
	}
}
