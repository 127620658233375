form,
.gform_wrapper form {
	@include clearfix;
	width: 100%;

	label {
		display: block;
		margin: 0 0 6px;
		font-size: 1.2rem;
		font-weight: 700;
		color: inherit;
		cursor: pointer;

		em {
			font-style: normal;
			color: $primary;
		}
	}

	select,
	input[type="color"],
	input[type="date"],
	input[type="datetime"],
	input[type="datetime-local"],
	input[type="email"],
	input[type="month"],
	input[type="number"],
	input[type="range"],
	input[type="search"],
	input[type="tel"],
	input[type="text"],
	input[type="time"],
	input[type="url"],
	input[type="week"],
	input[type="file"],
	textarea {
		display: block;
		width: 100%;
		background-color: $white;
		box-shadow: 0 0 3px rgba(0, 0, 0, .25);
		font-size: 1.2rem;
		color: $font-color;
		transition: all 150ms linear;
		padding: 0 12px;
		height: 32px;
		outline: 0;
		border: none;
		border-radius: 5px;
		appearance: none;

		&::-ms-expand { display: none; }

		&:hover,
		&:focus { outline: 0; box-shadow: 0 0 3px rgba(0, 0, 0, .5); }
	}

	input[type="file"] {
		cursor: pointer;
		height: 40px;
		margin-bottom: 5px;
		padding-top: 10px;
		position: relative;

		@media screen and (min-width: 640px) {
			max-width: 340px;
		}

		&::after {
			background: $primary $icon-upload no-repeat center;
			background-size: 20px;
			border-radius: 5px;
			content: '';
			display: block;
			height: 30px;
			position: absolute;
			right: 5px;
			top: 5px;
			width: 30px;
		}
	}

	select {
		cursor: pointer;
		background-image: $select;
		background-repeat: no-repeat;
		background-position: center right;
		padding-left: 10px;
		padding-right: 30px;
	}

	textarea { padding: 12px; }

	input[type="radio"],
	input[type="checkbox"] { 
		display: none;

		+ label {
			padding: 0 10px 0 20px;
			position: relative;
			display: inline-block;
			font-weight: 400;
			cursor: pointer;

			&:before {
				content: '';
				display: block;
				position: absolute;
				left: 0;
				width: 14px;
				height: 14px;
				border-radius: 50%;
				background: $white;
				box-shadow: 0 0 2px rgba(0, 0, 0, .25);
			}

			&:after {
				content: '';
				display: block;
				position: absolute;
				left: 2px;
				top: 2px;
				width: 10px;
				height: 10px;
				border-radius: 50%;
				background: $gainsboro;
				transform: scale(0);
				transition: all 150ms linear;
			}

			&:hover:after { 
				transform: scale(1);
			}
		}

		&:checked + label:after {
			transform: scale(1);
			background: $base;
		}
	}

	input[type="checkbox"] + label:before,
	input[type="checkbox"] + label:after {
		border-radius: 0;
	}

	.gfield_visibility_hidden {
		display: none !important;
	}
}

.c-filters {
	input[type="radio"],
	input[type="checkbox"] {
		cursor: pointer;
		appearance: none;
		display: inline-block;
		margin: 0;
		padding: 0;
		background-color: $white;
		border: 1px solid $black;
		transition: all $easing 300ms;
		position: relative;
		line-height: 1.5;
		box-shadow: inset 0 0 0 3px $white;
		cursor: pointer;

		&:focus {
			outline: none;
		}

		&::-ms-expand {
			display: none;
		}

		+ label {
			margin: 0 10px 0 15px;
		}

		&:checked {
			background-color: $primary;
		}
	}

	input[type="checkbox"] {
		border-radius: 0;
	}
	input[type="radio"] {
		border-radius: 50%;
	}

	input[type="radio"],
	input[type="checkbox"] {
		height: 20px;
		min-width: 20px;
		width: 20px;

		&:hover {
			background-color: $primary;
		}
	}

	input[type="radio"] {
		&:checked {
			background-color: $primary;
		}
	}

	input[type="checkbox"] {
		&:checked {
			background-color: $white;

			&::after {
				background-image: $icon-tick-accent;
				background-repeat: no-repeat;
				position: absolute;
				width: 24px;
				height: 18px;
				content: '';
				top: -2px;
				bottom: 0;
				left: 1px;
			}
		}
	}
}
