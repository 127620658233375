.c-popup {
	min-height: 100vh;
	@include stripes($smoke, "white");

	> div { 
		min-height: 100vh; 

		> div {
			padding: 40px;
			background: $white;
			box-shadow: 0 0 5px rgba(0, 0, 0, .2);
		}
	}

	h2 {
		margin-bottom: 20px;
		line-height: 1em;
		@include indent-title("small", 2.6rem, $font-color, $primary);
	}

	textarea { height: 100px; }
}