.c-nav-mobile {
	position: fixed;
	top: 0;
	right: -240px;
	width: 240px;
	height: 100vh;
	overflow-y: scroll;
	background: rgba(255, 255, 255, 0.85);
	z-index: z(nav-mobile);
	
	@media screen and (min-width: 1060px) { display: none; }

	&__logo {
		display: block;
		padding: 10px;
		background: $tuatara;
		img {
			margin: 0 auto;
		}
	}

	&__menu {
		border-top: 1px solid rgba(0, 0, 0, .1);
		border-bottom: 1px solid rgba(255, 255, 255, .1);

		&:last-of-type { border-top: 0; }

		> li {
			border-top: 1px solid rgba(255, 255, 255, .1);
			border-bottom: 1px solid rgba(0, 0, 0, .1);

			> a {
				position: relative;
				display: block;
				padding: 15px 20px;
				font-size: 1.4rem;
				color: $secondary;
				text-transform: uppercase;
				font-weight: 700;
				line-height: 1.4rem;
				transition: border .2s linear, padding-left .2s linear;
				border-left: 5px solid transparent;
			}

			&.active a {
				padding-left: 25px;
				border-left-color: $primary;
			}

			a + ul {
				display: none;

				> li {
					> a {
						display: block;
						padding: 15px 20px;
						font-size: 1.2rem;
						color: $secondary;
						text-transform: uppercase;
						line-height: 1.4rem;
						transition: border .2s linear, padding-left .2s linear;
						border-left: 5px solid transparent;

						&:hover {
							padding-left: 25px;
							border-left-color: $primary;

							+ ul { display: block; }
						}
					}

					&.active a {
						padding-left: 25px;
						border-left-color: $primary;
					}
				}
			}
		}
	}

	&__toggle {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		line-height: 44px;
		width: 44px;
		text-align: center;
		overflow: hidden;
		background: $primary;
		&.is-open {
			transform: rotate(180deg);
		}
		svg {
			width: 35%;
			fill: $white;
		}
	}
}