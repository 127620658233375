body {
  position: relative;
  background: $white;
  display: flex;
  flex: 1 1 auto;
  min-height: 100vh;
  flex-direction: column;

  > svg {
    display: none;
  }
}

main {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

svg {
  max-width: 100%;
  max-height: 100%;
}

i {
  display: inline-block;

  svg {
    display: block;
    fill: currentColor;
    float: left;
  }
}

section {

  @media print {
    padding: 10px 0;
  }
}

figure {
  margin: 0;
}

.table {
  margin: 0 0 20px;

  td {
    padding: 7px 10px;
    border: 1px solid $font-color;
  }
}
