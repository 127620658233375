.c-options {
	padding: 40px 0;

	h4 {
		color: $primary;
		font-size: 2.0rem;
		line-height: 2.3rem;
		margin-bottom: 5px;
		font-weight: 400;
	}

	.c-list {

		li {
			font-size: 1.6rem;
			font-weight: 300;
			padding-left: 30px;

			&:before {
				color: $secondary;
			}
		}

		@include breakpoint(medium up){
			columns: 2;
		}
		@include breakpoint(xlarge up){
			columns: 4;
		}
	}

	.c-button {
		float: none;
		margin: 0 auto;
		display: block;
		max-width: 250px;

		@include breakpoint(medium up){
			float: right;
			margin-right:20px;
		}

	}
}