.responsive-second {
	@include breakpoint(medium down){
		order: 2;
	}
}

.header-visual {
	overflow: hidden;
	padding: 0 0 30px;

	@media print {
		padding: 0 0 1.0rem;
	}

	&__slider {
		position: relative;
		li:not(:first-child) { display: none; }
	}
	&__nav {
		padding: 20px 0 0;
		overflow: hidden;

		@media print {
			display: none;
		}

		.no-mobile &,
		.no-tablet & {
			padding-left: 50px;
			padding-right: 50px;
		}

		ul {
			position: relative;
			display: block;
			margin: 0 -10px;
			width: calc(100% + 20px);
		}
		li {
			cursor: pointer;
			padding: 0 10px;

			&:not(:first-child) { display: none; }
			img {
				border: 1px solid $white;
				transition: all .3s;
			}
			&:hover img {
				border-color: $primary;
			}
		}
		.slick-arrow {
			&.prev,&.next {
				position: absolute;
				z-index: z(sliders-buttons);
				top: 0;
				bottom: 0;
				padding: 0 20px;
				width: 86px;
				overflow: hidden;
				cursor: pointer;
				transition: opacity .3s;

				&.slick-disabled {
					opacity: .4;
					cursor: default;
					pointer-events: none;
				}
				&:not(.slick-disabled):hover:after {
					background-color: darken($primary,5%);
				}
				svg {
					position: absolute;
					top: calc(50% - 23px);
					left: 0;
					height: 46px;
				}
				&:after {
					position: absolute;
					top: calc(50% - 21px);
					left: 22px;
					right: 0;
					bottom: 0;
					background: url(../img/svg/slider-pijl.svg) center no-repeat;
					content: "";
					width: 42px;
					height: 42px;
					border-radius: 50%;
					z-index: -1;
					transition: all .3s;
					background-color: $oil;
					@include box-shadow(0 0 4px $white);
				}
			}
			&.prev {
				left: 0;
				transform: rotateY(180deg);
			}
			&.next {
				right: 0;
			}
		}
	}
}