.c-news {
	position: relative;

	> div:first-of-type {
		position: relative;
		background: white;
	}

	&__grid {
		display: flex;
		flex-wrap: wrap;
		list-style: none;
		margin: 0;
		padding: 20px 0 0;

		@media screen and (min-width: 768px) {
			padding-top: 40px;
			padding-left: 20px;
			padding-right: 20px;
		}

		li {
			float: left;
			display: flex;
			flex: 1 1 100%;
			max-width: 100%;
			padding: 15px;

			@media screen and (min-width: 768px) {
				flex-basis: 50%;
				max-width: 50%;
			}

			@media screen and (min-width: 1024px) {
				flex-basis: 33.33%;
				max-width: 33.33%;
			}
		}
	}

	aside {
		padding: 20px 40px;

		@media screen and (min-width: 480px) { padding: 40px 40px; }
		@media screen and (min-width: 768px) { padding: 60px 40px; }
	}
}
