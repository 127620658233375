.c-about-us {
	padding: 20px 0 160px;
	background: $tertiary-color;
	overflow: auto;

	@media screen and (min-width: 450px){
		padding-top: 60px;
	}

	&__top-deals {
		display: block;
		margin-bottom: 40px;

		@media screen and (min-width: 450px){
			display: none;
		}
	}

	article {
		margin-bottom: 20px;

		@media screen and (min-width: 768px) { margin-bottom: 0; }

		div {
			p {
				margin: 20px;
				font-size: 1.6rem;
				line-height: 2.5rem;
				text-align: center;
				font-weight: 300;
				letter-spacing: 0.2px;
				color: $secondary;

				@media screen and (min-width: 450px) { margin: 50px 0; }
			}
		}
	}

	&__wrapper {
		font-size: 0;
		text-align: center;
	}
}
