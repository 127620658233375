.c-home {
	text-align: center;
	padding: 20px 0;
	position: relative;

	@media screen and (min-width: 660px) { padding: 40px 0 100px; }
	@media screen and (min-width: 1024px) { padding-bottom: 300px; }

	&:before {
		position: absolute;
		top: 0;
		left: 0;
		content: "";
		height: 65%;
		width: 100%;
		z-index: z(header-gradient);
		background: rgba(0,0,0,1);
		background: -moz-linear-gradient(top,  rgba(0,0,0,1) 0%, rgba(0,0,0,0.6) 12%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top,  rgba(0,0,0,1) 0%,rgba(0,0,0,0.6) 12%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom,  rgba(0,0,0,1) 0%,rgba(0,0,0,0.6) 12%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */

	}

	&__slider {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		list-style: none;

		> div {
			height: 100%;

			> div {
				height: 100%;

				li {
					display: block;
					width: 100%;
					height: 100%;
					background-size: cover;
					background-position: center;
				}
			}
		}
	}

	&__content {
		position: relative;
		text-shadow: 0 0 18px $black;
		padding-top: 100px;
		z-index: z(cta-bar);

		h2 {
			font-weight: 400;
			margin-bottom: 30px;
			font-size: 2.4rem;
			line-height: 1.8rem;
			color: $white;

			@media screen and (min-width: 660px) {
				font-size: 6.3rem;
				line-height: 6.3rem;
			}
		}

		p {
			font-size: 1.4rem;
			color: $white;
			margin: 0 auto 30px;

			@media screen and (min-width: 660px) {
				padding: 0 50px;
				font-size: 2.4rem;
			}
		}
	}

	&__wrapper {
		margin: -10px;
		font-size: 0;
		text-align: center;
	}

	&__button {
		position: relative;
		display: inline-block;
		margin: 10px;
		transition: all 150ms linear;
		z-index: z(cta-bar);
	}
}
