// Overrule jQuery inline styles
body {
  @media screen and (min-width: 1060px) { margin-left: 0 !important; }
}

/* Overrule Sanitezes text-rendering */
:root {
  text-rendering: optimizeSpeed;
}

/* No negitve margin on collapse row (foundation bug) */
.row .row[class$="collapse"] {
  margin-right: 0;
  margin-left: 0;
}

// Overrule inline styles from Facebook
.c-sitemap__like {
  @media screen and (min-width: 650px) {
    position: absolute!important;
  }
}

// Overrule FB JS styling
.c-latest-news__fb { display: table!important; }

// Overrule jQuery display none
.c-occasion-options article {
  @media print { display: block !important; }
}

.c-button {
  color: $white !important;
}

.c-widget {
  select { box-shadow: 0 0 1px rgba(0, 0, 0, .2)!important; }

  button {
    @extend .c-button;
    @extend .c-button--full;
  }
}

.slick-slider {
  opacity: 0;
  transition: opacity 1s linear;

  .slick-slide { display: none; }

  &.slick-initialized {
    opacity: 1;

    .slick-slide { display: block; }
  }
}