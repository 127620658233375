.c-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 0 20px;
  padding: 0;

  &__item {
    flex-basis: 100%;
    padding-left: 1.5em;
    position: relative;

    &:before {
      content: '';
      display: block;
      height: 10px;
      width: 10px;
      background: $play no-repeat;
      position: absolute;
      top: calc(50% - 5px);
      left: 0;
      transition: all 150ms ease;
    }

    &:hover:before {
      left: 2px;
    }
  }

  &--half {
    @include breakpoint(medium up) {
      flex-basis: 50%;
      max-width: 50%;
    }
  }

  &--play {

    .c-list__item {
      padding: 10px 10px 10px 18px;

      &::before {
        top: 15px;
      }
    }
  }

  &--check {
    .c-list__item {
      &::before {
        top: 0;
        color: currentColor;
        background: 0;
        display: inline-block;
        font-family: fontawesome;
        content: '\f00c';
      }

      &:hover::before {
        left: 0;
      }
    }
  }

  &--white {
    .c-list__item {
      &::before {
        color: $white;
      }
    }
  }

  &--clean {
    .c-list__item {
      padding-left: 0;
      &::before {
        display: none;
      }
    }
  }

  &--icons {
    .c-list__item {
      padding: 9px 0 8px 55px;
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }
      &:before {
        display: none;
      }
    }

    i {
      position: absolute;
      top: calc(50% - 17px);
      left: -50px;
      width: 35px;
      height: 35px;
      border-radius: 100%;
      border: 2px solid $white;
      color: $white;

      svg {
        width: 100%;
        height: 100%;
        padding: 5px;
        fill: $white;
      }
    }

    a {
      position: relative;
      color: $white;
      display: inline-block;
    }
  }
}
