.c-page-choices {
	&__container {
		display: flex;
		flex-wrap: wrap;
	}

	&__choice {
		display: flex;
		max-width: 100%;
		flex: 0 0 100%;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		min-height: 100%;
		padding: 20px;
		justify-content: center;
		box-shadow: 0 0 10px -5px $black inset;

		@media screen and (min-width: 480px) {
			padding: 40px;
		}

		@media screen and (min-width: 1200px) {
			max-width: 50%;
			flex: 0 0 50%;
			min-height: 400px;
		}

		&--threecolumns {
			@media screen and (min-width: 1200px) {
				max-width: 33.33%;
				flex: 0 0 33.33%;
			}
		}

		article {
			background: rgba(35, 35, 35, 0.45);
			padding: 20px;
			height: auto;
			text-align: center;
			max-width: 400px;
			width: 100%;
			transition: all 150ms linear;
			align-self: center;

			hr {
				width: 100%;
				background: $white;
				display: block;
				height: 1px;
				margin-bottom: 20px;
			}

			h3 {
				@include indent-title("large", calc(14px + (8 * ((100vw - 320px) / 640))), $white, $white);
				margin-bottom: 20px;
				text-align: center;
				text-shadow: 0 0 5px $black;
				font-weight: 500;
				line-height: 1;

				&:hover {
					color: $primary;
				}

				@media screen and (min-width: 1700px) {
					@include indent-title("large", 2.8rem, $white, $white);
				}
			}

			p {
				width: 100%;
				color: $white;
				text-shadow: 0 0 5px $black;
			}

			a:not(:first-child) {
				min-width: 230px;
				margin: 5px;
				box-shadow: 0 0 5px -2px $black;
			}

		}

		&:hover {
			padding: 0;
			min-height: 336px;

			@media screen and (min-width: 900px) {
				flex-direction: column;
			}

			article {
				background: rgba(35, 35, 35, 0.8);
				max-width: 100%;
				height: 100%;
				justify-content: center;
				align-items: center;
				display: flex;
				flex-flow: wrap;
				padding: 20px;

				@media screen and (min-width: 480px) {
					padding: 80px 40px;
				}

				@media screen and (min-width: 900px) {
					flex: 1 1 100%;
					padding: 40px;
				}
			}
		}
	}

	&__order {
		background-image: url(../img/lease-order.jpg);
	}

	&__calculator {
		background-image: url(../img/calculator.jpg);
	}

	&__available-stock {
		background-image: url(../img/available-stock.jpg);
	}

	&__stock {
		background-image: url(../img/stock.jpg);
	}

	&__register {
		background-image: url(../img/aanmelden.jpg);
	}

	&__book-out {
		background-image: url(../img/afmelden.jpg);
	}

	&__company {
		background-image: url(../img/bedrijfswagens.jpg);
	}

	&__private {
		background-image: url(../img/personenwagens.jpg);
	}

	&__report {
		background-image: url(../img/report.jpg);
	}
}