.gform_wrapper {
  width: 100%;
  max-width: 100%;
  margin: 0;
  @include clearfix;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .validation_error {
    border: 1px solid #f00;
    color: #f00;
    padding: 5px 10px;
    margin: 0 0 20px;
    background: #fcecec;
  }

  .gform_ajax_spinner {
    display: none;
  }

  .gform_header {
    clear: both;
  }

  .gform_body {
    display: flex;
    flex-wrap: wrap;
    margin: -10px;

    select {
      padding-left: 10px !important;
      padding-right: 30px !important;
    }

    .gform_fields {
      width: 100%;
      padding: 0;
      @include clearfix;

      .gfield {
        padding: 10px;
        display: block;
        @include clearfix;

        &.gform_column {
          display: none;
        }
        &.empty {
          display: none;
        }
        &.gform_hidden {
          display: none;
        }
        &.field_admin_only {
          display: none;
        }
        &.gform_validation_container {
          display: none;
        }
        &.gfield_error {
          input,
          textarea {
            border: 1px solid red;
          }
          .validation_message {
            color: red;
            font-size: 1.1rem;
            margin-top: 0;
          }
        }

        &.gfield_height {
          min-height: 74px;
        }

        &.gfield_inline ul li {
          display: inline-block;
        }

        .gfield_description {
          margin-top: 10px;
        }

        .gfield_required {
          padding-left: 3px;
        }

        .datepicker {
          background: $calendar no-repeat top right;
          padding-right: 32px;
        }

        textarea.small {
          height: 60px;
        }
        textarea.medium {
          height: 106px;
        }
        textarea.large {
          height: 180px;
        }
      }

      //Remove lists with the classes gsection and columns to save spaces which are added by the columns function.
      & > li.gsection.columns {
        display: none;
      }

      .gsection {
        font-size: 1.2em;
        padding-left: 1rem !important;

        &:not(:first-of-type) {
          border-top: 2px solid $iron;
          margin-top: 2rem;
        }
      }
    }
  }

  .gform_footer {
    padding-top: 20px;
    clear: both;
    @include clearfix;

    .gform_button {
      float: right;
    }

    &::before {
      display: block;
      content: "Velden met * zijn verplicht.";
      font-size: 1.2rem;
      line-height: 1em;
      float: left;

      @media screen and (max-width: 768px) {
        float: none;
        margin-bottom: 10px;
      }
    }
  }
}
