.slick-slider {
  opacity: 0 !important;
  transition: opacity 600ms linear !important;

  &.o-gallery__thumb-slider {
    display: none !important;
    transition: opacity 300ms linear, transform 550ms ease-in-out !important;

    &.slick-initialized {
      display: none !important;

      @media screen and (min-width: 640px) {
        display: block !important;
      }
    }
  }

  * {
    outline: none;
  }

  > div > div > * {
    display: none;
  }

  &.slick-initialized {
    display: block;
    opacity: 1 !important;

    > div > div > * {
      display: flex !important;
    }
  }

  &.show-swipe-icon {
    position: relative !important;

    &::after {
      background: rgba(0, 0, 0, 0.5);
      border-radius: 7px;
      content: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOS4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9Ii03MCAxNiA2MCAzMCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAtNzAgMTYgNjAgMzA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiNGRkZGRkY7fQ0KPC9zdHlsZT4NCjxnPg0KCTxnPg0KCQk8cG9seWdvbiBjbGFzcz0ic3QwIiBwb2ludHM9Ii0xNCwyNiAtMTYuNiwyNiAtMTMuNCwzMCAtMjAsMzAgLTIwLDMyIC0xMy40LDMyIC0xNi42LDM2IC0xNCwzNiAtMTAsMzEgCQkiLz4NCgkJPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSItNjYsMzYgLTYzLjQsMzYgLTY2LjYsMzIgLTYwLDMyIC02MCwzMCAtNjYuNiwzMCAtNjMuNCwyNiAtNjYsMjYgLTcwLDMxIAkJIi8+DQoJCTxnPg0KCQkJPHBhdGggY2xhc3M9InN0MCIgZD0iTS01NCwzMmMtMC42LDAtMS0wLjQtMS0xYzAtOC4zLDYuNy0xNSwxNS0xNWM4LjMsMCwxNSw2LjcsMTUsMTVjMCwwLjYtMC40LDEtMSwxYy0wLjYsMC0xLTAuNC0xLTENCgkJCQljMC03LjItNS44LTEzLTEzLTEzYy03LjIsMC0xMyw1LjgtMTMsMTNDLTUzLDMxLjYtNTMuNCwzMi01NCwzMiIvPg0KCQkJPHBhdGggY2xhc3M9InN0MCIgZD0iTS00MCwzNmMtNiwwLTUuMS03LjMtNS4xLTcuM3MxLTIuMyw1LjEtMi4zczUuMiwyLjMsNS4yLDIuM1MtMzQsMzYtNDAsMzYgTS00MCwyNGMtNi4zLDAtOC4xLDUuNy04LjEsMTkNCgkJCQl2MC42Qy00NS44LDQ1LjEtNDMsNDYtNDAsNDZjMywwLDUuOC0wLjksOC4xLTIuNFY0M0MtMzEuOSwyOS43LTMzLjcsMjQtNDAsMjQiLz4NCgkJPC9nPg0KCTwvZz4NCjwvZz4NCjwvc3ZnPg0K);
      display: none;
      left: 50%;
      padding: 7px 10px;
      pointer-events: none;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: opacity 300ms linear;
      width: 100px;

      .mobile & {
        display: block;
      }
    }

    &.swiped {
      &::after {
        opacity: 0;
      }
    }
  }
}
