.c-fleet-contacts {
  background: url(../img/lease-contracten.jpg) center no-repeat;
  background-size: cover;
  padding-bottom: 50px;
  padding-top: 20px;

  &__title {
    margin-bottom: 0;
  }

  &__table {
    border: solid 2px $black;
    margin: 0 auto 40px;
    text-align: left;
    width: 100%;
  }

  &__table-header {
    background-color: $black;
    color: $white;
    padding: 10px;
  }

  &__table-data {
    background-color: $white;
    border: solid $paragraph-color 1px;
    color: $paragraph-color;
    padding: 10px;

    &--centered {
      text-align : center;
    }
  }
}
