$footer-menu-height: 65px;

.c-footer {
    background: url(../img/newsletter-background.jpg) no-repeat center / cover;
    color: $white;
    padding: 15px 0;
    @include clearfix;

    @media print { display: none; }

    &__button {
        margin-bottom: 20px;
    }

    &__share {
      clear: both;
      float: left;

      @include breakpoint(medium up) {
        float: right;
      }
    }

    &__address {
        border-bottom: 1px solid $paragraph-color;
        padding: 40px 0 10px;

        h2 {
            font-weight: 600;
            margin-bottom: 15px;
            @include indent-title("large", 1.8rem, $white, $white);

            a {
                color: $white;
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        address {
            color: $paragraph-color;
            font-size: 1.4rem;
            font-style: normal;
            margin-bottom: 20px;

            a {
                color: $paragraph-color;
            }
        }

        &--logo {
            float: right;

            @media screen and (min-width: 768px) {
                margin-top: 0;
            }

            margin: 40px 0 20px;

            img {
                max-width: 150px;
                width: 100%;
            }
            @media screen and (min-width: 768px) {
                img {
                    width: 100%;
                    max-width: 100%;
                }
            }
            &:hover {
                opacity: 0.8;
            }
        }
    }

    &__links {
        float: none;

        @media screen and (min-width: 768px) { float: left; }

        ul {
            font-size: 0;
            list-style: none;
            text-align: center;

            li {
                display: inline-block;
                position: relative;

                &:before {
                    background: $base;
                    content: "";
                    height: 12px;
                    position: absolute;
                    right: 0;
                    top: calc(50% - 6px);
                    width: 1px;
                }

                &:last-of-type:before { display: none; }

                a {
                    color: $white;
                    display: block;
                    font-size: 1.4rem;
                    height: $footer-menu-height;
                    line-height: $footer-menu-height;;
                    padding: 0 15px;
                    transition: color 150ms ease;

                    &:hover { color: $jumbo; }
                }
            }
        }
    }

    .c-footer-menu {
        float: right;

        &__nav {
            display: none;
            float: right;
            height: $footer-menu-height;
            list-style: none;
            margin: 0;
            @include clearfix;

            @media screen and (min-width: 1060px) {
                display: block;
            }

            > li {
                float: left;
                position: relative;

                &.has-submenu {
                    > a:after {
                        content: '\f078';
                        display: inline-block;
                        font-family: fontawesome;
                        padding-left: 5px;
                    }
                }

                > a {
                    color: $white;
                    font-size: 1.4rem;
                    font-weight: 700;
                    line-height: $footer-menu-height;
                    padding: 15px 12px;
                    text-decoration: none;
                    text-transform: uppercase;
                }

                &.active {
                    border-bottom: 3px solid $white;
                    height: $footer-menu-height;
                }

                a + ul {
                    background: rgba(255, 255, 255, 0.95);
                    border-top: 3px solid $secondary;
                    bottom: 100%;
                    display: none;
                    list-style: none;
                    margin-left: -1px;
                    margin-top: -3px;
                    min-width: 220px;
                    position: absolute;
                    @include box-shadow(0px 3px 10px -3px $iron);

                    > li {

                        &:hover {
                            background: rgba(255, 255, 255, 1);
                            a {
                                color: #707070;
                            }
                        }

                        a {
                            color: $secondary;
                            display: block;
                            line-height: 1.4rem;
                            padding: 12px;
                            text-decoration: none;
                            text-transform: uppercase;
                        }
                    }
                }

                &.is-hover,
                &:hover {
                    border-bottom: 3px solid $white;
                    height: $footer-menu-height;

                    > ul {
                        display: block;
                    }
                }
            }
        }
    }
}
