@media print {
	* {
		background: none!important;
		color: black!important;
		font-size: 1.2rem;
	}

	input,
	button { display: none!important; }

	h1,
	h2,
	h3,
	h4 {
		display: block!important;
		width: 100%!important;
		padding-left: 0!important;
		font-size: 1.4rem!important;
		font-weight: 700!important;
		margin-bottom: 5px!important;

		small,
		span { 
			display: inline-block;
			font-size: 1.4rem!important;
			font-weight: 400!important; 
		}
	}
}