.c-inline-list {
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
	flex-wrap: wrap;

	&__item {
		position: relative;

		& + & {
			padding-left: 30px;
		}
	}
}