.c-dashboard {
	padding: 0;
	background: $tertiary-color url("../img/dashboard.jpg") center no-repeat;
	background-size: cover;
	position: relative;

	&::before {
		position: absolute;
		content: '';
		height: 100%;
		width: 100%;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(245, 245, 245, 0.9);
	}

	&__intro {
		padding: 60px 0 0;
		position: relative;

		h2 {
			@include indent-title("large", 2.4rem, $secondary, $secondary);
			font-weight: 400;
			text-align: center;
			padding: 0 20px;
			line-height: 1.2;

			@media screen and (min-width: 768px) {
				@include indent-title("large", 3.8rem, $secondary, $secondary);
			}
		}

		p {
			padding: 30px 20px;
			max-width: 1100px;
			margin: 0 auto;
			text-align: center;

			@media screen and (min-width: 768px) {
				font-size: 1.6rem;
				padding: 60px 20px;
			}
		}
	}

	&__grid {
		display: flex;
		flex-wrap: wrap;
		list-style: none;
		justify-content: center;
		padding: 0 20px 40px;
		max-width: 1300px;
		margin: 0 auto;
		position: relative;

		&--larger {
			max-width: 1920px;
		}
	}

	&__grid-item {
		display: flex;
		flex: 0 0 100%;
		max-width: 100%;
		padding: 10px;
		text-align: center;

		@media screen and (min-width: 500px) {
			flex: 1 1 50%;
			max-width: 50%;
		}

		@media screen and (min-width: 1024px) {
			flex: 1 1 33.33%;
			max-width: 33.33%;
		}

		@media screen and (min-width: 1460px) {
			flex: 1 1 20%;
			max-width: 20%;
		}

		article {
			padding: 20px;
			background: $white;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
		}

		svg {
			height: 42px;
			width: 100%;
			fill: $primary;
			margin-bottom: 15px;

			@media screen and (max-width: 768px) {
				margin: 30px 0 10px;
			}
		}

		h3 {
			@include indent-title("large", 1.8rem, $primary, $primary);
			font-weight: 300;
			display: flex;
			flex-basis: 100%;
			justify-content: center;
			align-items: center;
			border-bottom: 3px solid $tertiary-color;
			padding-bottom: 20px;
			margin-bottom: 20px;

			@media screen and (min-width: 768px) {
				font-size: 2.2rem;
			}
		}

		p {
			padding: 40px 0;
			margin: 0;
			font-size: 1.6rem;
			flex: 1 1 auto;
			font-weight: 300;
		}
	}

	&__buttongroup {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		flex-wrap: wrap;
		width: 100%;
		margin-bottom: auto;

		.c-button {
			width: 100%;
			margin: 14px auto auto;
			padding: 15px;
			font-weight: 400;
			position: relative;
			cursor: pointer;
			display: inline-flex;
			align-items: center;
			justify-content: center;

			@media screen and (min-width: 768px) {
				width: auto;
				min-width: 200px;
			}
		}
	}

	&__space {
		display: flex;
		height: 100%;
	}
}