.m-grid-view {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 0;

  &--spacious {
    margin: -20px;
  }

  &--centered {
    justify-content: center;
  }

  &__item {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    padding: 15px;
    width: 100%;

    &--spacious {
      padding: 20px;
    }

    @media screen and (min-width: 600px) {
      flex-basis: 50%;
      max-width: 50%;
    }

    @media screen and (min-width: 1200px) {
      flex-basis: 33.333%;
      max-width: 33.333%;
    }

    &--small {
      @media screen and (min-width: 480px) {
        flex-basis: 50%;
        max-width: 50%;
      }

      @media screen and (min-width: 800px) {
        flex-basis: 33.333%;
        max-width: 33.333%;
      }

      @media screen and (min-width: 1200px) {
        flex-basis: 25%;
        max-width: 25%;
      }
    }

    &--big {
      @media screen and (min-width: 480px) {
        flex-basis: 50%;
        max-width: 50%;
      }
    }
  }

  &__link {
    display: block;
    height: 100%;
    width: 100%;
  }

  &__message {
    margin: 0 10px;

    &--centered {
      text-align: center;
    }
  }
}
