.c-employees {
	padding: 40px 0;
	background: $tertiary;
	@include clearfix;

	.home & {
		background: $white;
	}

	&__grid {
		display: none;
		flex-wrap: wrap;
		list-style: none;
		max-width: 1100px;
		margin: 0 auto;
		padding: 0;

		&.slick-slider {
			display: flex;
		}

		.desktop & {
			padding-right: 30px;
			padding-left: 30px;

			@include breakpoint(large up){
				padding-right: 65px;
				padding-left: 65px;
			}
		}

		&--employees {
			li {
				float: left;
				display: flex;
				flex: 0 0 100%;
				max-width: 100%;
				padding: 10px;

				article {
					display: block;
					flex: 0 0 auto;
					width: 100%;

					&:last-of-type {
						margin-bottom: 30px;
						border-bottom: 0;

						@media screen and (min-width: 1024px) { margin-bottom: 0; }
					}

					figure {
						@include border-radius(50%);
						border: 10px solid white;
						margin: 30px 30px 0;

						img {
							@include border-radius(50%);
						}
					}

					div {
						display: flex;
						flex-direction: column;
						width: 100%;
						margin-left: 0;
						padding: 10px 15px;
						text-align: center;

						h4 {
							font-size: 2.0rem;
							font-weight: 300;
							color: $primary;
						}

						a {
							font-size: 1.4rem;
							color: $secondary;
							&:hover {
								color: $primary;
							}
						}
					}
				}
			}

			.slider-nav {
				&.prev, &.next {
					filter: none;
					-ms-filter: none;
					filter: none;
					width: auto;
					height: 30px;
				}
				&.prev {
					left: 0;
				}
				&.next {
					right: 0;
				}

				svg {
					fill: $paragraph-color;
				}
			}
		}
	}
}
