.c-specs-list {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;

  &__item {
    display: flex;
    padding-bottom: 10px;
    padding-top: 10px;
  }

  &__key {
    color: $black;
    flex-basis: 50%;
    flex-grow: 1;
    font-weight: bold;
    max-width: 50%;
  }

  &__value {
    flex-basis: 50%;
    flex-grow: 1;
    max-width: 50%;
  }

  &__link {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
