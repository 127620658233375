.c-page-header {
	position: relative;

	&:before {
		position: absolute;
		top: 0;
		left: 0;
		content: "";
		height: 55%;
		width: 100%;
		z-index: z(header-gradient);
		background: rgba(0,0,0,1);
		background: -moz-linear-gradient(top,  rgba(0,0,0,1) 0%, rgba(0,0,0,0.6) 12%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top,  rgba(0,0,0,1) 0%,rgba(0,0,0,0.6) 12%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom,  rgba(0,0,0,1) 0%,rgba(0,0,0,0.6) 12%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
	}

	&__slider {
		display: block;
		position: relative;
		height: 240px;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		list-style: none;

		@include breakpoint(medium down){
			height: 150px;
		}

		> div {
			height: 100%;

			> div {
				height: 100%;

				li {
					display: block;
					width: 100%;
					height: 100%;
					background-size: cover;
					background-position: top 15% center;
				}
			}
		}
	}

	@media print { display: none; }
}