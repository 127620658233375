.m-deal-card {
  align-content: space-between;
  display: flex;
  flex-wrap: wrap;
  font-size: 2rem;

  &__pricing {
    flex-basis: 50%;
    flex-grow: 1;
    min-width: 120px;

    &--cta {
      margin-bottom: 20px;
    }

    &--wide {
      flex-basis: 100%;
    }
  }

  &__label {
    font-size: 0.8em;
    font-weight: 300;
    line-height: 1;

    &--bold {
      font-weight: 700;
      font-size: 0.9em;
    }
  }

  &__price {
    font-size: 1.4em;
    line-height: 1;
  }
}
