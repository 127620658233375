.c-page-nav {
	background-color: $tertiary-color;
	margin-top: 65px;
	padding-bottom: 20px;
	padding-top: 20px;

	@media print {
		display: none;
	}

	&__container {
		align-items: center;
		display: flex;
		justify-content: space-between;
	}

	&__phone {
		@media screen and (max-width: 480px) {
			height: 40px;
			text-indent: -9999px;
			width: 40px;
			padding: 10px;
		}
	}
}
