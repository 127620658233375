.c-widget {
	color: $paragraph-color;
	border-bottom: 1px solid $ghost;
	padding: 40px 0;

	@media screen and (min-width: 1024px) { margin: 0 30px; }
	@media screen and (min-width: 1200px) { margin: 0 40px; }

	&:first-of-type { padding-top: 0; }

	img { margin-bottom: 10px; }

	h3 {
		color: $secondary;
		font-size: 2.4rem;
		margin-bottom: 10px;
		font-weight: 400;
	}

	h4 { 
		color: $secondary;
		font-size: 1.8rem;
		font-weight: 300;
		margin-bottom: 15px;
	}

	h3 small,
	h4 small { display: inline-block; }
	
	p { 
		color: $paragraph-color;
		font-size: 1.4rem;
		font-weight: 300;
	}

	.c-button {
		margin: 0 auto;
		width: 225px;
		display: block;
	}

	ul {
		list-style: none;

		li { 
			display: flex; 
    		flex-wrap: wrap;
			margin-bottom: 10px;
			@include clearfix;
			
			label,
			label.radio { 
				flex: 1;

				span {
					font-weight: 400;
					height: 20px;
				}
			}

			a { 
				color: $paragraph-color;
				flex: 1;
				margin-right: 15px;
				text-decoration: underline;
			}
		}
	}
}