.c-news-item {
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	width: 100%;
	padding: 0;
	background: $tertiary-color;
	position: relative;
	border-bottom: 1px solid $ghost;
	transition: box-shadow .3s;
	@include clearfix;

	&:hover {
		@include box-shadow(0px 3px 10px -3px $iron);
	}

	figure {
		width: 100%;
		display: block;
	}

	div {
		width: 100%;
		padding: 20px;
		flex-grow: 1;
		display: flex;
		flex-direction: column;

		h4 {
			font-size: 1.8rem;
			font-weight: 400;
			display: block;
			color: $primary;
			margin-bottom: 15px;

			time {
				display: block;
				font-size: 1.4rem;
				font-weight: 300;
				color: $paragraph-color;
				margin-right: 15px;
			}
		}

		p {
			font-size: 1.6rem;
			color: $paragraph-color;
			font-weight: 300;
		}

		span {
			width: 100%;
			font-family: $menu-font-family;
			text-transform: uppercase;
			font-size: 1.2rem;
			color: $secondary;
			padding-left: 20px;
			position: relative;
			margin-top: auto;

			&:before {
				font-family: fontawesome;
				content: '\f054';
				display: block;
				height: 8px;
				width: 8px;
				position: absolute;
				top: 0;
				left: 0;
			}
		}
	}
}
