.contactgegevens {
	background: white;
	padding: 40px 0 55px;

	&__titel {
		text-align: center;
		font-size: 3.8rem;
		margin-bottom: 40px;
		small {
			font-weight: 200;
		}
		strong {
			font-weight: 300;
		}
	}

	&__adres {

		h2 {
			font-size: 2.4rem;
			strong {
				font-weight: 400;
			}
		}
	}

	&__info { margin: 20px 0; }

	&__contact {
		margin: 0 0 20px;
		padding: 30px;
		background: $tertiary;

		&__content {
			margin: 0 0 20px;
			text-align: center;
			border-bottom: 1px solid $secondary;
			@include clearfix;
		}

		.btn {
			display: block;
			width: 190px;
		}
	}
}