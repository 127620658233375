.o-plm {
  &__container {
    padding-top: 40px;
  }

  &__grid {
    margin-bottom: 20px;
    margin-top: 20px;
    width: calc(100% + 30px);
  }
}
