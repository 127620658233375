.c-title {
  color: $font-color;
  font-family: $menu-font-family;
  font-size: $heading-font-size;
  line-height: 1;
  text-transform: uppercase;
  width: 100%;

  &--smaller {
    font-size: $heading-font-size * 0.7;
    font-weight: 300;
  }

  &--larger {
    font-size: $heading-font-size;
  }

  &--lowercase {
    text-transform: none;
  }

  &--centered {
    text-align: center;
  }

  &--margin {
    margin-bottom: 2rem;
  }
}
