@mixin clearfix {
    *zoom: 1;

    &:before,
    &:after {
        display: table;
        content: "";
        line-height: 0;
    }
    
    &:after { clear: both; }
}

@mixin stripes($background, $color) {
	background-color: $background;
	background-size: 3px 3px;
	background-repeat: repeat;

	@if $color == "grey" { 
		background-image: $stripes-grey;
	}

	@if $color == "white" { 
		background-image: $stripes-white;
	}
}

@mixin indent-title($indent, $font-size, $color-small, $color-large) {
	font-size: $font-size;
	color: $color-large;

	small {
		color: $color-small;
		line-height: 1;
	}

	@media print {
		padding-left: 0;
		text-indent: 0;
		display: inline-block;

		small {
			text-indent: 0;
			padding-left: 0;
			display: inline-block;
		}
	}
}

@mixin arrow($arrow-position, $arrow-direction, $arrow-face, $arrow-index) {
	position: relative;
	z-index: $arrow-index;

	> div {
		position: relative;

		@if $arrow-face == back {
			$content-index: $arrow-index + 2;
			z-index: $content-index;
		} @else if $arrow-face == front {
			$content-index: $arrow-index - 1;
			z-index: $content-index;
		}
	}

	&:before,
	&:after {
		position: absolute;
		display: block;
		height: 70px;
		width: 50%;
		background-color: inherit;
		content: "";
		z-index: $arrow-index;
		pointer-events: none;
	}

	&:before {
		left: 0;
		.has-cta-bar & { width: calc(50% + 35px); }
	}

	&:after {
		right: 0;
		.has-cta-bar & {
			right: -70px;
			width: calc(50% + 35px);
		}
	}

	@if $arrow-position == top {
		&:before,
		&:after {
			bottom: calc(100% - 35px);
		}
	} @else if $arrow-position == bottom {
		&:before,
		&:after  {
			top: calc(100% - 35px);
		}
	}

	@if $arrow-direction == up {
		&:before { transform: skew(0deg, -3deg) }
		&:after { transform: skew(0deg, 3deg) }
	} @else if $arrow-direction == down {
		&:before { transform: skew(0deg, 3deg) }
		&:after { transform: skew(0deg, -3deg) }
	}
}

// Appearance
@mixin appearance($appearance) {
	-moz-appearance: $appearance;
	-webkit-appearance: $appearance;
	-o-appearance: $appearance;
	appearance: $appearance;
}

// Border Radius
@mixin border-radius($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	border-radius: $radius;
}

// Drop shadows
@mixin box-shadow($shadow...) {
	-webkit-box-shadow: $shadow;
	-moz-box-shadow: $shadow;
	box-shadow: $shadow;
}

// Transitions
@mixin transition($transition...) {
	-webkit-transition: $transition;
	-moz-transition: $transition;
	-o-transition: $transition;
	transition: $transition;
}
