.c-pagination {
    clear: both;
    display: flex;
    font-size: 0;
    justify-content: center;
    list-style: none;
    margin: 20px 0;
    padding: 0;
    text-align: center;

    &__number {
        display: inline-block;
        vertical-align: middle;

        a {
            position: relative;
            display: block;
            padding: 15px;
            font-family: $font-family;
            font-size: 1.6rem;
            line-height: 20px;
            color: $base;
            transition: all 150ms linear;

            &:hover {
                background: $ghost;
                color: $oil;
            }
        }

        &.active {
            a {
                font-weight: 700;
                background: $primary;
                color: $white;
            }
        }
    }

    &__button {
        display: inline-block;
        vertical-align: middle;

        a {
            display: block;
            background: $primary;
            padding: 5px;
            margin: 15px 0;
            transition: all 150ms linear;

            i {
                display: block;
                width: 10px;
                height: 10px;

                svg {
                    float: left;
                    width: 10px;
                    height: 10px;
                    fill: $white;
                    transition: all .3s ease-in-out;
                }
            }

            &:hover { transform: scale(1.2); }
        }

        &--prev a {
            margin-right: 30px;

            i { transform: rotate(180deg); }
        }

        &--next a { margin-left: 30px; }
    }
}
