.responsive-first {
	@include breakpoint(medium down){
		order: 1;
	}
}

.header-visual-aside {
	position: relative;
	margin: 0 0 2.0rem;
	padding: 2.0rem 2.0rem 0;
	width: 100%;
	background: $tertiary;
	@include breakpoint(large up){
		margin-left: 10px;
	}

	@include clearfix;

	@media print {
		padding: 0;
	}

	&--flex {
		display: flex;
		flex-direction: column;
		padding-bottom: 2.0rem;
		height: 100%;
		.flex-space {
			@include breakpoint(xxlarge up){
				flex: 1 1 auto;
			}
		}
	}

	h3 {
		margin: 10px 0 20px;
		font-weight: 300;
		font-size: 1.8rem;
		strong {
			display: block;
			font-weight: 500;
		}

		@include breakpoint(xxlarge up){
			font-size: 2.5rem;
		}
	}

	&__deal {
		padding: 20px 0;
		background: $secondary;
		text-align: center;
		width: calc(100% + 60px);
		margin-left: -30px;
		position: relative;

		&:before, &:after {
			position: absolute;
			display: block;
			content: "";
			top: -10px;
			width: 0;
			height: 0;
			border-style: solid;
			transition: all 150ms ease;
		}

		&:before {
			left: 0;
			border-width: 0 0 10px 10px;
			border-color: transparent transparent #707070 transparent;
		}

		&:after {
			right: 0;
			border-width: 0 10px 10px 0;
			border-color: transparent transparent #707070  transparent;
		}

		h4 {
			align-self: center;
			width: 100%;
			display: block;
			color: $white;
			font-size: 1.8rem;
			text-transform: uppercase;
			font-weight: 400;
			font-family: $menu-font-family;

			@include breakpoint(xxlarge up){
				font-size: 2.6rem;
			}
		}
	}

	.c-list {
		margin: 30px 0;

		li {
			display: flex;
			flex-wrap: wrap;
			font-size: 1.4rem;
			font-weight: 300;
			@include clearfix;
			&:before {
				display: none;
			}

			label {
				flex: 0 0 95px;
				font-weight: 600;
			}

			@include breakpoint(medium up){
				font-size: 1.8rem;
				label {
					flex: 0 0 130px;
				}
			}
		}
	}

	&__price {
		font-size: 2.6rem;
		line-height: 2.7rem;
		color: $primary;
		font-weight: 300;
		margin-bottom: 20px;
		strong {
			display: block;
			font-size: 4.0rem;
			line-height: 4.2rem;
			font-weight: 600;
		}

		@include breakpoint(xxlarge up){
			font-size: 4.6rem;
			line-height: 4.7rem;
			strong {
				font-size: 6.0rem;
				line-height: 6.2rem;
			}
		}
	}

	.c-button {
		margin: 0 auto 25px;
		display: block;
		max-width: 250px;
	}

	small {
		font-size: 1.2rem;
	}
}