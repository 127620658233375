.c-alerts {
  margin: 0 auto;
  font-size: 16px;
  min-width: 300px;
  text-align: center;

  &.alert {
	position: relative;
	padding: .75rem 1.25rem;
	margin-bottom: 1rem;
	border: 1px solid transparent;
	border-radius: .25rem;

	&.alert-success {
	  color: #155724;
	  background-color: #d4edda;
	  border-color: #c3e6cb;
	}
  }
}