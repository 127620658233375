.m-card {
  background-color: $tertiary-color;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 1px;
  position: relative;
  transition: all 300ms $easing;
  width: 100%;
  z-index: 1;

  &.is-active {
    z-index: 9999;
  }

  &.is-hovered {
    box-shadow: 0 0 5px rgba($black, 0.3);
    transform: scale(1.02);
    z-index: 2;
  }

  &.is-faded:not(.is-hovered) {
    filter: grayscale(1);
    opacity: 0.5;
  }

  &--highlighted {
    height: 100%;
  }

  &__heading {
    font-size: $font-size;
    padding: 10px;
  }

  &__figure {
    display: block;
    flex-shrink: 0;
    height: auto;
    width: 100%;
  }

  &__image {
    width: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 15px;
    width: 100%;

    &--centered {
      justify-content: center;
    }
  }

  &__title {
    font-size: 2.4rem;
    width: 100%;

    &--margin {
      margin-bottom: 20px;
    }
  }

  &__deal {
    margin-bottom: 20px;
    width: 100%;
  }

  &__caption {
    margin-bottom: 10px;
  }

  &__details {
    margin-bottom: 20px;
  }

  &__button {
    margin-top: auto;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}
