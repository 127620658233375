:root { font-size: 62.5%; }

body {
	font-family: $font-family;
	font-size: $font-size;
	font-weight: $font-weight;
	font-style: $font-style;
	line-height: $font-line-height;
	color: $font-color;
}

a {
	outline: none;
	color: inherit;
}

p { margin: 0 0 20px; }

h1,
h2,
h3,
h4,
h5,
h6 {
	color: $secondary;

	small {
		display: block;
		font-weight: 400;
		font-size: 50%;
	}

	&.title {
		font-weight: 400;
		text-align: center;
		margin-bottom: 20px;
		display: none;

		@include breakpoint(large up){
			@include indent-title("large", 3.8rem, $secondary, $secondary);
			display: block;
		}
	}
}

em {
	font-style: normal;
	color: $primary;
}

dl {
	margin-bottom: 20px;
	font-size: 0;
}

dt,
dd {
	display: inline-block;
	font-size: $font-size;
}

dt {
	width: 150px;
	font-weight: 700;
	vertical-align: top;
}

dd {
	margin-bottom: 7px;
	width: calc(100% - 150px);
}

.address {
	margin: 0 0 20px;
	font-size: 1.5rem;
	font-style: normal;
	~ h6 { font-weight: 700; }
	~ dl dd { font-weight: 400; }

	a {
		color: $primary;
		&:hover { text-decoration: underline; }
	}
}
