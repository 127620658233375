.c-price {
  align-items: center;
  display: flex;
  line-height: 1.2;
  margin-bottom: 1rem;
  width: 100%;

  &__label {
    color: $font-color;
    flex-basis: 40%;
    margin-bottom: 0;
  }

  &__price {
    flex-basis: 60%;
    margin-bottom: 0;

    &--large {
      color: $primary;
      font-size: 3.8rem;
    }

    &--primary {
      color: $primary;
    }
  }
}
