.c-reviews {
	position: relative;
	display: none;
	padding: 25px 0 15px;
	color: $white;
	background: $primary;

	@media (min-width: 510px){
		display: block;
	}

	@media (min-width: 770px){
		padding-bottom: 35px;
	}

	&::before {
		position: absolute;
		top: 14px;
		left: 50%;
		width: 70px;
		height: 12px;
		transform: translateX(-50%);
		background: url(../img/svg/star.svg) 0 0 repeat;
		content: "";

		@media (min-width: 770px){
			top: 17px;
		}
	}

	&__title {
		font-size: 1.6rem;
		font-weight: 300;
		color: inherit;

		@media (min-width: 770px){
			font-size: 2.4rem;
		}

		strong {
			font-weight: 600;
			font-size: 2.4rem;

			@media (min-width: 770px){
				font-size: 4.0rem;
			}

			span {
				@media (min-width: 770px){
					position: relative;
					top: 6px;
				}
			}
		}
	}
}