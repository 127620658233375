.c-deal-services {
	background: $secondary;
	color: $paragraph-color;

	&__content {
		padding: 60px 0 60px 40px;
		position: relative;
		float: left;

		&:before {
			position: absolute;
			right:100%;
			top: 0;
			content: '';
			display: block;
			background-repeat: no-repeat;

			height: 100%;
			width: 1260px;
			background-size: contain;
			background-position: top right;
		}


		h2 {
			font-weight: 300;
			@include indent-title("large", 3.8rem, $white, $white);
			line-height: 4.5rem;
			margin-bottom: 50px;
		}


		.c-list {
			font-size: 2.0rem;
		}

		@media screen and (max-width: 768px) {
			h2 {
				font-size: 3.2rem;
				line-height: 3.4rem;
			}
			.c-list {
				font-size: 1.4rem;
			}
		}

		@media screen and (max-width: 460px) {
			padding: 20px;
			h2 {
				font-size: 2.4rem;
				line-height: 3rem;
				margin-bottom: 20px;
			}
		}
	}
}