.c-app {
	background: $black url(../img/app-bg.jpg) no-repeat center / cover;
	color: $monsoon;
	font-size: 1.4rem;

	@media screen and (min-width: 461px) {
		font-size: 2rem;
	}

	&__content {
		background-image: linear-gradient(to right, $black 0%, transparent);
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 30px 0;
		position: relative;

		@media screen and (min-width: 900px){
			min-height: 460px;
		}

		&::before {
			background-color: $black;
			bottom: 0;
			content: "";
			position: absolute;
			right: 100%;
			top: 0;
			width: 2000px;
		}
	}

	&__list {
		margin-bottom: 60px;
	}
}
