/* - - [ Citroën ] - - */

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Montserrat-Regular.woff') format('woff'),
	url('../fonts/Montserrat-Regular.ttf')  format('truetype'),
	url('../fonts/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Montserrat-Bold.woff') format('woff'),
	url('../fonts/Montserrat-Bold.ttf')  format('truetype'),
	url('../fonts/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
	font-weight: 700;
	font-style: normal;
}