.c-deal-specs {
	padding: 20px 0;

	&__container {
		background: $tertiary;
		padding: 20px;
		display: flex;

		h4 {
			color: $primary;
			font-size: 2.0rem;
			line-height: 2.3rem;
			margin-bottom: 5px;
			font-weight: 400;
		}

		p {
			font-weight: 300;
			margin: 0;
			font-size: 1.6rem;
		}

		&--info {
			flex: 0 0 100%;
			@include breakpoint(large up){
				flex: 0 0 40%;
				padding-right: 40px;
				border-right: 4px solid #e4e4e4;
				max-width: 40%;
			}
		}

		&--specifications {
			flex: 0 0 100%;
			padding: 40px 0 0 0;

			@include breakpoint(large up){
				flex: 0 0 60%;
				padding: 0 0 0 40px;
				max-width: 60%;
			}

			.c-list {

				li {
					display: flex;
					flex-wrap: wrap;
					font-size: 1.4rem;
					font-weight: 300;
					padding: 0 5px;
					border-bottom: 1px solid $secondary;

					&:last-child {
						border: none;
					}

					@include clearfix;
					&:before {
						display: none;
					}

					label {
						flex: 0 0 60%;
					}

					span {
						padding-left: 10px;
					}

					@include breakpoint(medium up){
						font-size: 1.6rem;
					}

					@include breakpoint(xlarge up){
						width: 50%;
						float: left;
					}
				}				
			}
		}
	}
}