.c-stock {
	padding: 20px 0;
	@include clearfix;
	
	.flex-container {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-bottom: 20px;
		justify-content: flex-end;

		&--logo {
			justify-content: center;

			@media screen and (min-width: 580px) {
				justify-content: flex-end;
			}
		}
	}

	&__logo {
		max-height: 100px;
		flex-basis: 250px;
		max-width: 180px;
		float: right;
		margin: 0 0 20px;
		align-self: flex-end;
		order: 1;

		@media screen and (min-width: 580px) {
			order: 2;
		}

		@media screen and (min-width: 768px) {
			max-width: 250px;
		}
	}

	&__title {
		font-weight: 400;
		text-align: center;
		margin-bottom: 20px;
		@include indent-title("large", 2.2rem, $secondary, $secondary);

		@media screen and (min-width: 768px) {
			@include indent-title("large", 3.8rem, $secondary, $secondary);
		}

		&--logo {
			flex-basis: 100%;
			max-width: 100%;
			padding-right: 20px;
			margin: 0;
			order: 2;

			@media screen and (min-width: 580px) {
				flex-basis: calc(100% - 250px);
				max-width: calc(100% - 250px);
				order: 1;
			}
		}
	}

	&__grid {
		padding: 0;
	}

	&-result {
		position: relative;
		background: $tertiary;
		margin: 0 0 40px;
		padding: 20px 20px 0;

		.border {
			position: relative;

			@media screen and (min-width: $grid-row-width) {
				padding-left: 30px;
			}

			&::before {
				@media screen and (min-width: $grid-row-width) {
					position: absolute;
					top: 0;
					left: 10px;
					bottom: 20px;
					width: 1px;
					background: $iron;
					content: "";
				}
			}
		}

		&--message {
			font-size: 2.0rem;
			text-align: center;
		}

		&--sold {
			&::before {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: z(stock);
				background: rgba(255,255,255,.7);
				content: "";
			}

			&::after {
				position: absolute;
				top: 50%;
				right: 0;
				left: 0;
				transform: translateY(-50%);
				z-index: z(stock);
				font-size: 50px;
				font-weight: 700;
				text-align: center;
				content: "NIET MEER BESCHIKBAAR";
			}
		}

		&__image {
			margin-bottom: 20px;
		}

		&__title {
			margin-bottom: 30px;
			font-size: 2.4rem;

			small {
				font-size: 75%;
			}
		}

		dt, dd {
			@media screen and (max-width: 460px) {
				width: 100%;
			}
		}

		dd {
			@media screen and (max-width: 460px) {
				margin-bottom: 10px;
			}
		}

		&__specifications {
			&--wide {
				dt, dd {
					display: block;
					width: 100%;
				}

				dd {
					margin-bottom: 10px;
				}
			}
		}

		.c-button {
			margin-bottom: 20px;
		}
	}
}