.c-filters {
	columns: 2;
	width: 100%;
	margin-top: 20px;
	margin-bottom: 20px;

	&__item {
		display: flex;
		flex: 0 0 50%;
		font-size: 1rem;
		margin-bottom: 10px;
		align-items: center;

		@media screen and (min-width: 540px) {
			flex: 0 0 33.333%;
			font-size: 1.2rem;
		}

		@media screen and (min-width: 700px) {
			font-size: 1.4rem;
			margin-top: 10px;
		}

		@media screen and (min-width: 1024px) {
			flex: 0 0 20%;
		}

		&--reset {
			flex: 1 1 0;
			text-align: right;
		}

		&--seperator {
			flex: 1 1 100%;
			height: 1px;
			background-color: $iron;
		}
	}
}