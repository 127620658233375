.c-vehicle-information {
  background-color: $white;
  font-family: $menu-font-family;
  padding-bottom: 20px;
  padding-top: 20px;

  @media print {
    background-color: transparent;
    margin-top: 0;
    padding-bottom: 10mm;
    padding-top: 0;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-left: 0;
    padding-right: 0;

    @media screen and (min-width: 1024px) {
      padding-left: 20px;
      padding-right: 20px;
    }

    @media screen and (min-width: 1340px) {
      flex-direction: row;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__header {
    flex-basis: 100%;
    margin-bottom: 20px;
  }

  &__gallery {
    flex-basis: 100%;
    max-width: 100%;
    padding-bottom: 0;
    padding-top: 0;

    @media (min-width: 1024px) {
      flex-basis: 60%;
      max-width: 60%;
    }

    @media print {
      flex-basis: 100%;
      max-width: 100%;
    }
  }

  &__slider {
    margin-bottom: 0;
  }

  &__content {
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    padding: 0 20px;

    @media (min-width: 1024px) {
      flex-basis: 450px;
      max-width: 40%;
      padding: 0 0 0 60px;
    }

    @media print {
      flex-basis: 100%;
      max-width: 100%;
      padding: 10mm 0 0 0;
    }
  }

  &__prices {
    border-bottom: 1px solid $black;
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    justify-content: space-between;
    margin-bottom: 3rem;

    @media (min-width: 640px) {
      font-size: $font-size * 1.5;
    }
  }

  &__specs {
    border-bottom: 1px solid $black;

    @media print {
      display: none;
    }
  }

  &__spec {
    display: flex;
    padding-bottom: 2rem;
  }

  &__spec-label {
    color: $font-color;
    flex-basis: 40%;
    margin-bottom: 0;
    max-width: 40%;
  }

  &__spec-value {
    flex-basis: 60%;
    margin-bottom: 0;
    max-width: 60%;
  }

  &__footer {
    margin-top: 2rem;

    @media print {
      display: none;
    }
  }

  &__button {
    margin-top: 2rem;
  }
}
