.c-intro {
	padding-top: 20px;

	&__logo {
		max-height: 100px;
		flex-basis: 250px;
		max-width: 180px;
		margin: 0 0 20px;

		@media screen and (min-width: 768px) {
			max-width: 250px;
		}
	}

	&__title {
		font-weight: 400;
		margin-bottom: 50px;
		text-align: center;
		@include indent-title("large", 2.2rem, $secondary, $secondary);

		@media screen and (min-width: 768px) {
			@include indent-title("large", 3.8rem, $secondary, $secondary);
		}

		small {
			color: $font-color;
			font-size: 1.6rem;
		}

		&--centered {
			margin-bottom: 20px;
		}
	}
}
