.visual {
	position: relative;
	background: white;
	width: 100%;
	min-height: 610px;
	overflow: hidden;

	@media screen and (max-height: 685px) {
		min-height: calc(100vh - 175px);
	}

	.touch & .slick-slider:after {
		display: none;
	}

	&--vestiging {
		.visual__scroll {
			text-shadow: 1px 1px white, -1px -1px white, -1px 1px white, 1px -1px white, 0 0 1px white;
		}

		.google-maps__maps {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}
	}

	&__scroll {
		position: absolute;
		bottom: 40px;
		left: calc(50% - 70px);
		width: 140px;
		font-size: 14px;
		font-weight: 700;
		text-align: center;
		text-transform: uppercase;
		color: $primary;
		z-index: 99;
		text-shadow: 0 0 4px rgba(0, 0, 0, 0.35);

		i {
			display: block;
			height: 40px;
			width: 40px;
			margin: 8px auto 0;
			border-radius: 50%;
			background: $primary;
			transition: all 0.3s cubic-bezier(0.47, 2.02, 0.31, -0.36);

			svg {
				float: left;
				width: 40px;
				height: 40px;
				fill: $white;
			}
		}

		&:hover {
			color: $black;

			i {
				transform: scale(1.1);
			}
		}
	}
}