.c-category-acties {
	padding: 40px 0;
	@include clearfix;

	&__grid {
		display: flex;
		flex-wrap: wrap;
		list-style: none;
		margin: -10px;
		padding: 0;

		li {
			float: left;
			display: flex;
			flex: 0 0 calc(100% - 20px);
			max-width: calc(100% - 20px);
			padding: 10px;
			margin: 10px;

			@media screen and (min-width: 460px) {
				flex: 1 1 calc(50% - 20px);
				max-width: calc(50% - 20px);
			}

			@media screen and (min-width: 1024px) {
				flex: 1 1 calc(33.333% - 20px);
				max-width: calc(33.333% - 20px);
			}

			@media screen and (min-width: 1280px) {
				flex: 1 1 calc(25% - 20px);
				max-width: calc(25% - 20px);
			}

			figure {
				display: block;
				flex: 0 0 auto;
				width: 100%;
				background: $tertiary-color;

				figcaption {
					display: flex;
					padding: 10px 0;
					background: $secondary;
					text-align: center;
					width: calc(100% + 20px);
					margin-left: -10px;
					position: relative;

					&:before, &:after {
						position: absolute;
						display: block;
						content: "";
						top: -10px;
						width: 0;
						height: 0;
						border-style: solid;
						transition: all 150ms ease;
					}

					&:before {
						left: 0;
						border-width: 0 0 10px 10px;
						border-color: transparent transparent #707070 transparent;
					}

					&:after {
						right: 0;
						border-width: 0 10px 10px 0;
						border-color: transparent transparent #707070  transparent;
					}

					h4 {
						align-self: center;
						width: 100%;
						display: block;
						color: $white;
						font-size: 1.8rem;
						text-transform: uppercase;
						font-weight: 400;
						font-family: $menu-font-family;
					}
				}

				div {
					padding: 20px;

					h5 {
						font-size: 2.0rem;
						font-weight: 300;

						strong {
							display: block;
						}
					}
					
					.price {
						font-size: 2.8rem;
						color: $primary;
						font-weight: 700;
						padding: 20px 0;
						display: block;
					}

				}

				a {
					img {
						transition: all 150ms ease;
					}
					&:hover {
						img	{
							max-width: calc(100% + 20px);
							margin-left: -10px;
							margin-top: -9px;
						}
					}
				}

				a:hover + figcaption {
					background: $primary;
					&:before, &:after {
						bottom: -10px;
						top: auto;
					}
					&:after {
						transform: rotate(90deg);
					}
					&:before {
						transform: rotate(-90deg);
					}
				}

				.c-button {
					width: 165px;
					margin: 0 auto;
					padding: 15px;
					display: block;
					font-weight: 400;
				}
			}
		}
	}
}