.c-share {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	justify-content: right;
	list-style: none;
	margin: 0 -7px 10px;
	text-align: right;

	&__item {
		margin: 0 7px 10px;
	}

	&__icon {
		color: $base;
		display: block;
		height: 26px;
		width: 26px;

		&:hover {
			color: $primary;
		}

		&--social-media {
			background: $base;
			border-radius: 50%;
			color: $white;

			&:hover {
				background: $primary;
				color: $white;
			}
		}

		&--light {
			background: $white;
			color: $font-color;
		}

		&--large {
			height: 37px;
			width: 37px;
		}
	}
}
