.c-specifications {
  padding-top: 40px;

  @media print {
    padding-bottom: 40px;
    padding-top: 0;
  }

  &__container {
    padding: 0;
  }

  &__title {
    margin-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;

    @media screen and (min-width: 1024px) {
      margin-bottom: 60px;
    }
  }

  &__subtitle {
    font-size: 2rem;
    margin-bottom: 10px;
  }

  &__specs {
    flex-basis: 100%;
  }

  &__lists {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media screen and (min-width: 768px) {
      justify-content: space-between;
    }

    @media print {
      display: block;

      &::after {
        clear: both;
        content: '';
        display: table;
      }
    }
  }

  &__list {
    flex-basis: 100%;
    margin-bottom: 20px;
    max-width: 480px;
    padding-left: 20px;
    padding-right: 20px;

    @media screen and (min-width: 768px) {
      flex-basis: 50%;
      max-width: 50%;
    }

    @media screen and (min-width: 1024px) {
      flex-basis: 33.33%;
      max-width: 33.33%;
    }

    @media print {
      float: left;
      width: 33.33%;
    }
  }
}
