.c-latest-news {
	padding: 40px 0;
	background: $tertiary-color;
	@include clearfix;

	&__grid {
		flex-wrap: wrap;
		max-width: 1100px;
		margin: 30px auto 0;
		padding: 0;
		list-style: none;
		display: none;

		&.slick-slider {
			display: flex;
		}

		.desktop & {
			padding-right: 30px;
			padding-left: 30px;

			@include breakpoint(large up){
				padding-right: 65px;
				padding-left: 65px;
			}
		}
		@include breakpoint(xlarge up){
			margin-bottom: 40px;
		}

		&--news {
			li {
				float: left;
				display: flex;
				flex: 0 0 100%;
				max-width: 100%;
				padding: 10px;

				a {
					width: 100%;
					display: block;

					&:hover {
						@include box-shadow(0px 3px 10px -3px $iron);
					}

					article {
						display: flex;
						flex-direction: column;
						flex: 1 1 auto;
						background: $white;

						figure {
							width: 100%;
							display: block;
							flex: 0 0 auto;

							img {
								width: 100%;
							}
						}

						&:last-of-type {
							margin-bottom: 30px;
							border-bottom: 0;

							@media screen and (min-width: 1024px) { margin-bottom: 0; }
						}

						div {
							display: flex;
							flex-direction: column;
							width: 100%;
							margin-left: 0;
                            padding: 25px 15px;

                            h4 {
                                font-size: 1.8rem;
                                font-weight: 300;
                                margin-bottom: 15px;
                            }

							p {
                                font-size: 1.6rem;
							 	flex: 1 1 auto;
                                color: $paragraph-color;
                                font-weight: 300;
							}

							span {
								font-family: $menu-font-family;
								text-transform: uppercase;
								font-size: 1.2rem;
								color: $secondary;
								padding-left: 20px;
								position: relative;

								&:before {
									font-family: fontawesome;
									content: '\f054';
									display: block;
									height: 8px;
									width: 8px;
									position: absolute;
									top: 0;
									left: 0;
									transition: all 150ms ease;
								}

								&:hover:before { left: 2px; }
							}
						}
					}
				}
			}

			.slider-nav{
				&.prev, &.next {
					filter: none;
					-ms-filter: none;
					filter: none;
					width: auto;
					height: 30px;
				}
				&.prev {
					left: 0;
				}
				&.next {
					right: 0;
				}

				svg {
					fill: $paragraph-color;
				}
			}
		}
	}

	&__more {
		margin: 0 auto;
		display: block;
		width: 250px;

		@media screen and (min-width: 1330px) {
			margin-right: 165px;
			float: right;
			width: auto;
		}
	}
}
