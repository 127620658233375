.c-services {
	padding: 30px 0;
	background: $white;
	overflow: visible;
	@include clearfix;

	@media screen and (min-width: 768px) {
		padding: 80px 0;
	}

	&__grid {
		display: flex;
		flex-wrap: wrap;
		list-style: none;
		margin: -10px;
		padding: 0;

		li {
			float: left;
			display: flex;
			flex-direction: column;
			flex: 0 0 100%;
			max-width: 100%;
			padding: 0 40px;
			text-align: center;

			@media screen and (min-width: 768px) {
				flex: 1 1 50%;
				max-width: 50%;
			}

			@media screen and (min-width: 1024px) {
				flex: 1 1 33.333%;
				max-width: 33.333%;
			}

			svg {
				height: 42px;
				width: 100%;
				fill: $primary;
				margin-bottom: 30px;
				@include transition(all .3s);
				&:hover {
					fill: $secondary;
					transform: scale(1.2);
				}

				@media screen and (max-width: 768px) {
					margin: 30px 0 10px;
				}
			}

			h3 {
				font-weight: 300;
				@include indent-title("large", 2.4rem, $primary, $primary);
			}

			p {
				padding: 40px 0;
				margin: 0;
				font-size: 1.6rem;
				flex: 1 1 auto;
				font-weight: 300;
			}

			.c-button {
				width: 100%;
				max-width: 200px;
				margin: 0 auto;
				padding: 15px;
				font-weight: 400;
			}
		}

		@media screen and (max-width: 1024px) {
			li {
				padding: 20px;
				p {
					padding: 15px 0;
				}
			}
		}

		@media screen and (max-width: 768px) {
			li {
				h3 {
					font-size: 1.8rem;
					text-align: center;
				}
			}
		}
	}
}