.c-container {
  margin: 0 auto;
  max-width: $grid-row-width;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  width: 100%;

  &--large {
    max-width: 1920px;
  }
}
