/*
 * ~~~~~~~~~~~~~~~~~~~~
 * Stylesheet for imports.
 * This glues the stylesheets together.
 * ~~~~~~~~~~~~~~~~~~~~
 */

@import "../../node_modules/foundation-sites/scss/foundation";

@include foundation-flex-classes;
@include foundation-flex-grid;
.no-flexbox { @include foundation-grid; }
@include foundation-visibility-classes;

@import "../settings/images";

@import "../tools/functions";
@import "../tools/mixins";

@import "../generic/magnific";
@import "../generic/sanitize";
@import "../generic/slick";

@import "../base/fonts";
@import "../base/type";
@import "../base/elements";
@import "../base/forms";
@import "../base/print";

@import "../components/buttons";
@import "../components/section-title";
@import "../components/list";
@import "../components/inline-list";
@import "../components/filters";
@import "../components/canvas";
@import "../components/topbar";
@import "../components/footer";
@import "../components/nav-mobile";
@import "../components/breadcrumbs";
@import "../components/page-header";
@import "../components/home-slider";
@import "../components/visual";
@import "../components/contactgegevens";
@import "../components/about-us";
@import "../components/home-pages";
@import "../components/services";
@import "../components/core-values";
@import "../components/news";
@import "../components/latest-news";
@import "../components/employees";
@import "../components/popup";
@import "../components/category-acties";
@import "../components/header-visual";
@import "../components/header-visual-aside";
@import "../components/deal";
@import "../components/deal-services";
@import "../components/deal-specs";
@import "../components/options";
@import "../components/versions";
@import "../components/page";
@import "../components/entry";
@import "../components/pagination";
@import "../components/news-item";
@import "../components/widget";
@import "../components/share";
@import "../components/reviews";
@import "../components/page-choice";
@import "../components/stock";
@import "../components/dashboard";
@import "../components/intro";
@import "../components/alerts";
@import "../components/page-nav";
@import "../components/link";
@import "../components/title";
@import "../components/container";
@import "../components/vehicle-information";
@import "../components/gallery";
@import "../components/price";
@import "../components/specifications";
@import "../components/specs-list";
@import "../components/order";
@import "../components/fleet-contracts";
@import "../components/app";
@import "../components/plm";
@import "../components/grid-view";
@import "../components/card";
@import "../components/deal-card";

@import "../trumps/helpers";
@import "../trumps/trumps";
@import "../trumps/sanitize";
@import "../trumps/gravity-forms";
@import "../trumps/slick";
