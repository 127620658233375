/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;

    &.show-swipe-icon {
        .mobile &,
        .tablet & {
            &:after {
                position: absolute;
                opacity: 1;
                left: 50%;
                width: 100px;
                padding: 7px 10px;
                background: rgba(0, 0, 0, .5);
                content: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOS4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9Ii03MCAxNiA2MCAzMCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAtNzAgMTYgNjAgMzA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiNGRkZGRkY7fQ0KPC9zdHlsZT4NCjxnPg0KCTxnPg0KCQk8cG9seWdvbiBjbGFzcz0ic3QwIiBwb2ludHM9Ii0xNCwyNiAtMTYuNiwyNiAtMTMuNCwzMCAtMjAsMzAgLTIwLDMyIC0xMy40LDMyIC0xNi42LDM2IC0xNCwzNiAtMTAsMzEgCQkiLz4NCgkJPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSItNjYsMzYgLTYzLjQsMzYgLTY2LjYsMzIgLTYwLDMyIC02MCwzMCAtNjYuNiwzMCAtNjMuNCwyNiAtNjYsMjYgLTcwLDMxIAkJIi8+DQoJCTxnPg0KCQkJPHBhdGggY2xhc3M9InN0MCIgZD0iTS01NCwzMmMtMC42LDAtMS0wLjQtMS0xYzAtOC4zLDYuNy0xNSwxNS0xNWM4LjMsMCwxNSw2LjcsMTUsMTVjMCwwLjYtMC40LDEtMSwxYy0wLjYsMC0xLTAuNC0xLTENCgkJCQljMC03LjItNS44LTEzLTEzLTEzYy03LjIsMC0xMyw1LjgtMTMsMTNDLTUzLDMxLjYtNTMuNCwzMi01NCwzMiIvPg0KCQkJPHBhdGggY2xhc3M9InN0MCIgZD0iTS00MCwzNmMtNiwwLTUuMS03LjMtNS4xLTcuM3MxLTIuMyw1LjEtMi4zczUuMiwyLjMsNS4yLDIuM1MtMzQsMzYtNDAsMzYgTS00MCwyNGMtNi4zLDAtOC4xLDUuNy04LjEsMTkNCgkJCQl2MC42Qy00NS44LDQ1LjEtNDMsNDYtNDAsNDZjMywwLDUuOC0wLjksOC4xLTIuNFY0M0MtMzEuOSwyOS43LTMzLjcsMjQtNDAsMjQiLz4NCgkJPC9nPg0KCTwvZz4NCjwvZz4NCjwvc3ZnPg0K);
                border-radius: 7px;
                transition: all .3s;
                z-index: z(sliders-buttons);
                pointer-events: none;
            }

            &.is-swiped:after {
                opacity: 0;
            }
        }
    }

    .slick-list {
        position: relative;
        overflow: hidden;
        height: 100%;
        width: 100%;
        display: block;
        margin: 0;
        padding: 0;

        &:focus {
            outline: none;
        }

        &.dragging {
            cursor: pointer;
            cursor: hand;
        }

        .slick-track {
            position: relative;
            height: 100%;
            @include clearfix;

            @media print {
                left: 0 !important;
            }

            li {
                float: left;
                display: block;
                height: 100%;
                min-height: 1px;

                @media print {
                    &:not(:first-child) {
                        display: none;
                    }
                }

                a:hover {
                    opacity: 1;
                }
            }
        }
    }

    .slick-slide {
        float: left;
        height: 100%;
        min-height: 1px;

        [dir='rtl'] & {
            float: right;
        }

        > a {
            position: relative;
            display: block;
            transition: all .3s;

            &[href*="watch?v="],
            &[href*="youtu"],
            &[href*="player.vimeo."] {

                &:before {
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    background: rgba(0, 0, 0, .1);
                    content: "";
                }

                &:after {
                    position: absolute;
                    z-index: 2;
                    top: calc(50% - 30px);
                    left: calc(50% - 30px);
                    width: 60px;
                    content: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOS4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9Ii04MSAxMSAzOSAzOSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAtODEgMTEgMzkgMzk7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiNGRkZGRkY7fQ0KPC9zdHlsZT4NCjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0tODEsMzAuNUMtODEsMTkuNy03Mi4zLDExLTYxLjUsMTFDLTUwLjcsMTEtNDIsMTkuNy00MiwzMC41Uy01MC43LDUwLTYxLjUsNTBDLTcyLjMsNTAtODEsNDEuMy04MSwzMC41eiIvPg0KPHBhdGggZD0iTS01NC41LDMwLjVsLTkuOSwxMC4zbC0yLTJsOC04LjJsLTgtOC4ybDItMkwtNTQuNSwzMC41eiIvPg0KPC9zdmc+DQo=);
                }
            }
        }
    }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}

.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow {
    outline: 0;
    focus: none;
}
.slick-arrow.slick-hidden {
    display: none;
}

.slider-nav {
    position: absolute;
    width: 35px;
    height: auto;
    z-index: 2;
    top: calc(50% - 22px);
    -webkit-filter: drop-shadow(0 0 5px #000);
    filter: drop-shadow(0 0 5px #000);
    -ms-filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=5, OffY=5, Color='#000')";
    filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=5, OffY=5, Color='#000')";

    &:hover:not(.slick-disabled) {
        cursor: pointer;
        svg {
            -webkit-transform: scale(1.2);
            -ms-transform: scale(1.2);
            transform: scale(1.2);
        }
    }

    &.prev { left: 10%; }
    &.next { right: 10%; }

    svg {
        fill: $white;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        height: 30px;
        width: 20px;
        $z-index: z(slider-nav);
    }

    &.slick-disabled { opacity: .5; cursor: default; }
}
