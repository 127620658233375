.c-breadcrumbs {
	padding: 25px 0;
	border-bottom: none;
	font-size: 1.2rem;
	position: relative;
	z-index: 1;

	@media screen and (min-width: 768px) { font-size: 1.4rem; }

	@media print { display: none; }

	ul {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			float: left;
			position: relative;
			padding: 0 15px 0 5px;
			font-weight: 600;

			@media screen and (min-width: 768px) { padding: 0 20px 0 10px; }

			&:after {
				content: url(../img/svg/breadcrumb-arrow.svg);
				display: block;
				height: 7px;
				width: 7px;
				position: absolute;
				top: 1px;
				right: 0;
				transition: all 150ms ease;
				@media screen and (min-width: 768px) { height: 7px; width: 7px; }
			}
			
			a {
				color: $paragraph-color;
				&:hover {
					color: $secondary;
				}
			}

			&:first-of-type { padding-left: 0; }
			
			&:last-of-type { 
				color: $primary;

				&:after { display: none; }
			}
		}
	}
}