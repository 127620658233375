button,
.c-button {
  position: relative;
  appearance: none;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  padding: 15px 40px;
  font-family: $menu-font-family;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.6rem;
  text-align: center;
  text-transform: uppercase;
  color: $white;
  background: $primary;
  border-radius: 30px;
  border: 0;
  transition: all 300ms linear;

  i {
    width: 15px;
    height: 15px;
    top: calc(50% - 7px);
    position: absolute;
    transition: transform 300ms cubic-bezier(.47, 2.02, .31, -.36);

    &.c-button__icon {
      @media screen and (max-width: 480px) {
        left: 50%;
        transform: translateX(-50%);
      }
    }

    svg {
      width: 100%;
      height: 100%;
      fill: $white;
    }
  }

  &:hover {
    background: darken($primary, 8%);

    i {
      transform: scale(1.2);

      &.c-button__icon {
        @media screen and (max-width: 480px) {
          transform: translateX(-50%) scale(1.2);
        }
      }
    }
  }

  &--big {
    padding: 20px 15px;
    font-size: 1.5rem;

    @media screen and (min-width: 768px) {
      font-size: 1.7rem;
    }
  }
  &--small {
    padding: 8px;

    i {
      width: 14px;
      height: 14px;
      top: calc(50% - 7px);
    }
  }

  &--center {
    text-align: center;
    padding: 10px 0;
  }

  &--denver {
    padding: 10px 30px;
  }

  &--full {
    width: 100%;
  }

  &--half {
    width: 100%;

    @media screen and (min-width: 480px) {
      width: calc(50% - 10px);

      &:first-of-type {
        float: left;
        margin-right: 10px;
      }
      &:last-of-type {
        float: right;
        margin-left: 10px;
      }
    }
  }

  &--gray {
    background: $base;

    &:hover {
      background: $iron;
    }
  }

  &--icon {
    &-right {
      padding: 15px 48px 15px 15px;

      i {
        right: 15px;
      }
    }

    &-left {
      padding: 15px 30px 15px 60px;

      i {
        left: 30px;
      }
    }
  }

  &--arrow {
    i {
      right: 15px;
      width: 10px;
      height: 10px;
      top: calc(50% - 5px);
    }
  }
}
