.c-versions {
	background: $tertiary;
	padding: 40px 0;
	@include clearfix;

	h2 {
		font-weight: 400;
		text-align: center;
		margin-bottom: 15px;
		@include indent-title("large", 2.4rem, $secondary, $secondary);
		@media screen and (min-width: 1024px) {
			margin-bottom: 30px;
			@include indent-title("large", 3.8rem, $secondary, $secondary);
		}
	}

	&__grid {
		display: flex;
		flex-wrap: wrap;
		list-style: none;
		margin: -10px;
		padding: 0;

		>li {
			float: left;
			display: flex;
			flex: 0 0 calc(100% - 20px);
			max-width: calc(100% - 20px);
			padding: 20px;
			margin: 10px;
			background: $white;
			flex-direction: column;
			font-size: 1.4rem;
			font-weight: 300;

			@media screen and (min-width: 460px) {
				flex: 1 1 calc(50% - 20px);
				max-width: calc(50% - 20px);
			}

			@media screen and (min-width: 1024px) {
				flex: 1 1 calc(33.333% - 20px);
				max-width: calc(33.333% - 20px);
			}

			h3 {
				font-size: 2rem;
				line-height: 2.4rem;
				font-weight: 300;
				strong {
					font-size: 2.4rem;
					font-weight: 400;
					display: block;
				}
			}

			.c-button {
				display: block;
				margin: 0 auto;
				max-width: 250px;
			}
		}

		&--price {
			font-weight: 400;
			font-size: 2.4rem;
			color: $primary;
			line-height: 2.4rem;
			margin: 15px 0 20px;

			small {
				display: block;
				font-size: 1.6rem;
			}
		}
	}


	h4 {
		color: $primary;
		font-size: 2.0rem;
		line-height: 2.3rem;
		margin-bottom: 5px;
		font-weight: 400;
	}

	.c-list {

		li {
			font-size: 1.4rem;
			font-weight: 300;
			padding-left: 20px;

			&:before {
				color: $secondary;
			}
		}
	}
}