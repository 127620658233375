.c-order {
  background-color: $canvas;
  padding-bottom: 40px;
  padding-top: 40px;

  @media screen and (min-width: 830px) {
    background-color: $tertiary;
  }

  @media print {
    display: none;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-left: 0;
    padding-right: 0;

    @media screen and (min-width: 1024px) {
      padding-left: 20px;
      padding-right: 20px;
    }

    @media screen and (min-width: 1340px) {
      flex-direction: row;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__form {
    background-color: $canvas;
    max-width: 780px;
    padding: 20px;
  }
}
