.c-entry {
	font-family: $font-family;
	font-size: $font-size;
	font-weight: $font-weight;
	font-style: $font-style;
	line-height: $font-line-height;
	color: $font-color;
	width: 100%;
	word-break: break-word;

	@include clearfix;

	.c-button { margin: 0 0 10px; }

	h1, h2, h3, h4, h5, h6 { margin: 0 0 .5em; }

	.c-title {
		font-weight: 400;
		text-align: center;
		@include indent-title("large", 3.8rem, $secondary, $secondary);
	}

	h1 { font-size: 28px; }
	h2 { font-size: 26px; }
	h3 { font-size: 24px; }
	h4 { font-size: 22px; }
	h5 { font-size: 20px; }
	h6 { font-size: 18px; }

	em {
		font-style: italic;
		color: currentColor;
	}

	hr {
		border: 0;
		margin: 0 0 20px;
		width: 100%;
		height: 1px;
		background: $smoke;
	}

	p { margin: 0 0 2em; }

	a {
		color: $primary;
		transition: all 300ms;

		&:hover { color: darken($primary, 8%); }
	}

	ol, ul:not(.c-share):not(.gform_fields) {
		margin: 0 0 2em 1.5em;

		ol, ul { margin: 0 0 0 1.5em; }
	}

	ol { list-style: decimal; }
	ul { list-style: disc; }

	img {
		border: 0 !important; // Vereist om WP te overschrijven.

		&.aligncenter {
			display: block;
			margin-right: auto;
			margin-left: auto;
		}

		&.alignleft {
			float: left;
			margin-right: 15px;
		}

		&.alignright {
			float: right;
			margin-left: 15px;
		}

		@media screen and (max-width: 480px) {
			display: block;
			width: 100%;
			height: auto;

			&.aligncenter,
			&.alignleft,
			&.alignright {
				float: none;
				margin: 0;
			}
		}
	}

	blockquote {
		background: $snow;
		margin: 0 0 2em;
		padding: 20px;
		border-left: 10px solid $smoke;

		*:last-of-type { margin-bottom: 0; }
	}

	form { max-width: 600px; }
}
