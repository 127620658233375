.deal {
	margin: 0 0 30px;
	font-size: 0;
	li {
		@include clearfix
	}
	.slick-track {
		display: block;
		margin: 0 auto;
		border-left: 1px solid $gainsboro;
	}
	.slick-list {
		border-left: 1px solid $gainsboro;
	}
	@media (min-width: 1025px) and (max-width: 1366px){
		padding: 0 60px;
		border: none;
		.prev { left: -20px; }
		.next { right: -20px; }
	}
	@include clearfix;
	&__item {
		display: inline-block;
		width: 100%;
		font-size: $font-size;
		color: $black;
		border: solid $gainsboro;
		border-width: 1px 1px 1px 0;
		@include breakpoint(medium up){
			width: 50%;
		}
		@include breakpoint(xlarge up){
			width: 33.33%;
		}
		&__titel {
			font-weight: 400;
			color: $primary;
		}
		&__content {
			margin: 20px 0;
			padding: 0 1.0rem;
			text-align: center;
			@include breakpoint(xxlarge up){
				text-align: left;
				border-right: 1px solid $gainsboro;
			}
			p {
				display: none;
				@include breakpoint(xlarge up){
					display: block;
				}
			}
		}
		&__voordeel {
			text-align: center;
			font-size: 1.6rem;
			line-height: 1.15;

			@include breakpoint(large up){
				font-size: 3.0rem;
			}
			strong {
				display: block;
				font-weight: bolder;
			}
			* {
				font-size: inherit;
				font-weight: inherit;
				line-height: inherit;
				text-align: inherit;
				color: inherit;
			}
		}
		&__afbeelding {
			width: 100%;
		}
	}

	&__afbeelding {
		position: relative;
		width: 100%;
		margin: 0 0 10px;
		border: 10px solid;
		border-color: $ghost;
		img {
			width: 100%;
		}
		&__deal {
			position: absolute;
			top: -15px;
			right: -15px;
			display: table;
			padding: 15px;
			width: 160px;
			height: 160px;
			color: $white;
			font-size: 2.8rem;
			font-weight: 700;
			line-height: 1.1;
			text-align: center;
			text-transform: uppercase;
			vertical-align: middle;
			background: $primary;
			border-radius: 50%;
			span {
				display: table-cell;
				vertical-align: middle;
			}
		}
		&__voordeel {
			position: absolute;
			right: 10px;
			bottom: 10px;
			left: 10px;
			padding: 10px 15px;
			text-align: center;
			font-size: 2.5rem;
			font-weight: 700;
			color: $primary;
			background: rgba(255,255,255,.85);
			box-shadow: 2px 2px 8px rgba(0,0,0,.2);
			pointer-events: none;
			&--half {
				@include breakpoint(xlarge up){
					right: auto;
					width: calc(50% - 15px);
				}
				+ figcaption {
					display: none;
					left: auto;
					right: 10px;
					@include breakpoint(xlarge up){
						display: block;
					}
				}
			}
			span {
				font-weight: 400;
			}
		}
	}
}