/*
 * ~~~~~~~~~~~~~~~~~~~~
 * Trumps - Sanitize
 * Utilities and helper classes to override styles.
 * ~~~~~~~~~~~~~~~~~~~~
 */

// specify solid border style of all elements

*,
::before,
::after {
	border-style: none;
}