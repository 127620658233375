$topbar-height: 65px;

.c-topbar {
	font-family: $menu-font-family;
	position: fixed;
	width: 100%;
	z-index: z(topbar);
	height: $topbar-height;
	line-height: $topbar-height;
	color: $white;
	font-size: 1.4rem;
	border-bottom: 1px solid #646667;
	$z-index: z(topbar);
	background: rgba(0,0,0,0.75);
	backdrop-filter: blur(0);
	transition: transform 300ms linear;
	transform: translateY(-100%);

	&--light {
		border-color: $iron;
	}

	.row {
		z-index: 1;
	}

	&.is-visible {
		transform: translateY(0);
	}

	&:before {
		position: absolute;
		top: 0;
		left: 0;
		content: "";
		height: 100%;
		width: 100%;
		z-index: z(header-gradient);
		background: rgba(0,0,0,1);
		background: -moz-linear-gradient(top,  rgba(0,0,0,1) 0%, rgba(0,0,0,0.6) 30%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top,  rgba(0,0,0,1) 0%,rgba(0,0,0,0.6) 30%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom,  rgba(0,0,0,1) 0%,rgba(0,0,0,0.6) 30%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */

	}

	@media print { display: none; }

	.page & {
		background: rgba(0,0,0,0);

		&--fill {
			background: rgba(0,0,0,0.75);
			backdrop-filter: blur(4px);

			&:before {
				display: none;
			}
		}
	}

	&--fill {
		background: rgba(0,0,0,0.75);
		backdrop-filter: blur(4px);

		&:before {
			display: none;
		}
	}

	&__logo {
		display: block;
		float: left;
		margin: 10px 0;

		@media print { display: block; }

		img { display: block; }
	}

	&__login {
		float: right;
		background: rgba(0,0,0,0.75);
		padding: 5px;
		color: #808080;
		text-align: center;
		font-size: 1.2rem;
		line-height: 1.2rem;
		text-transform: uppercase;
		display: none;

		img {
			max-width: 100px;
			padding: 5px;
		}

		@media screen and (min-width: 1060px) {
			display: block;
		}
	}

	&__toggle {
		position: relative;
		width: 50px;
		height: 40px;
		margin: 10px 20px 0;
		float: right;

		@media screen and (min-width: 1060px) { display: none; }

		span {
			position: absolute;
			width: 50px;
			height: 8px;
			background: $primary;

			&:nth-of-type(1) {
				top: 0;
				left: 0;
				transition: all .3s ease-out;
			}

			&:nth-of-type(2) {
				top: 16px;
				left: 0;
				transition: all .15s ease-in;
			}

			&:nth-of-type(3) {
				left: 0;
				bottom: 0;
				transition: all .3s ease-out;
			}
		}

		&.is-active span {
			position: absolute;
			width: 50px;
			height: 8px;
			background: $primary;

			&:nth-of-type(1) {
				top: 16px;
				transform: rotate(45deg);
			}

			&:nth-of-type(2) {
				opacity: 0;
			}

			&:nth-of-type(3) {
				top: 16px;
				transform: rotate(-45deg);
			}
		}
	}

	.c-topbar-menu {
		float: right;
		height: $topbar-height;

		&__nav {
			display: none;
			float: right;
			height: $topbar-height;
			list-style: none;
			margin: 0;
			@include clearfix;

			@media screen and (min-width: 1060px) {
				display: block;
			}

			> li {
				position: relative;
				float: left;

				&.has-submenu {
					> a:after {
						display: inline-block;
						padding-left: 5px;
						font-family: fontawesome;
						content: '\f078';
					}
				}

				> a {
					font-size: 1.4rem;
					line-height: $topbar-height;
					color: $white;
					font-weight: 700;
					text-decoration: none;
					text-transform: uppercase;
					padding: 15px 12px;
				}

				&.active {
					height: $topbar-height;
					border-bottom: 3px solid $white;
				}

				a + ul {
					list-style: none;
					display: none;
					position: absolute;
					min-width: 220px;
					margin-left: -1px;
					margin-top: -3px;
					border-top: 3px solid $secondary;
					background: rgba(255, 255, 255, 0.95);
					@include box-shadow(0px 3px 10px -3px $iron);

					> li {

						&:hover {
							background: rgba(255, 255, 255, 1);
							a {
								color: #707070;
							}
						}

						a {
							display: block;
							padding: 12px;
							color: $secondary;
							text-decoration: none;
							text-transform: uppercase;
							line-height: 1.4rem;
						}
					}
				}

				&.is-hover,
				&:hover {
					height: $topbar-height;
					background-color: rgba(0,0,0,0.45);
					border-bottom: 3px solid $white;

					> ul {
						display: block;
					}
				}
			}
		}
	}

	&__links {
		list-style: none;
		display: none;
		margin: 0;
		@include clearfix;

		@media screen and (min-width: 900px) { display: inline-block; }

		li {
			float: left;
			margin: 0 15px;

			a {
				display: block;
				color: $secondary;
				font-weight: 700;
				text-decoration: none;
				text-transform: uppercase;
				transition: color 150ms ease;

				&:hover {
					color: $jumbo;

					> i svg { fill: $jumbo; }
				}
			}
		}
	}
}
