.c-gallery {
  &__slider {
    display: none;
    flex: 1 1 100%;
    max-width: 100%;
    overflow: hidden;
    padding-bottom: 40px;
    transition: transform linear 300ms;

    @media screen and (min-width: 1024px) {
      padding-bottom: 80px;
    }

    &::before {
      background-image: linear-gradient(to top, rgba($black, 0.8), transparent);
      bottom: 0;
      content: '';
      height: 30%;
      left: 0;
      opacity: 0;
      position: absolute;
      right: 0;
      transition: opacity linear 300ms;
      z-index: z(content-fade);
    }

    &:hover {
      &::before {
        opacity: 1;
      }
    }

    &--relative {
      &::before {
        display: none;
      }
    }
  }

  &__figure {
    background: $black;
    display: block;
    position: relative;
    width: 100%;
  }

  &__link {
    display: block;
  }

  &__fullscreen {
    bottom: 1em;
    color: $white;
    height: 1em;
    position: absolute;
    right: 1em;
    transition: all $easing 100ms;
    width: 1em;

    &:hover {
      transform: scale(1.25);
    }

    @media screen and (min-width: 768px) {
      height: 2em;
      width: 2em;
    }

    @media print {
      display: none;
    }
  }

  &__image {
    width: 100%;

    &--transparent {
      opacity: 0.6;
    }
  }

  &__icon {
    background-color: $white;
    background-image: $icon-arrow-accent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 10px;
    border: 1px solid $primary;
    border-radius: 100%;
    display: block;
    height: 45px;
    width: 45px;

    @media print {
      display: none;
    }

    &--next {
      padding-left: 5px;
    }

    &--prev {
      padding-right: 5px;
    }
  }

  &__nav {
    cursor: pointer;
    padding: 20px 5px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: padding 200ms;
    z-index: 2;

    @media screen and (min-width: 1024px) {
      bottom: -40px;
      top: auto;
    }

    &[aria-disabled="false"] {
      &:hover {
        padding-left: 8px;
        padding-right: 2px;
      }
    }

    &[aria-disabled="true"] {
      cursor: default;
      opacity: 0.25;
    }

    &--next {
      right: 0;

      @media screen and (min-width: 1024px) {
        left: 60px;
        right: auto;
      }
    }

    &--prev {
      left: 0;
      transform: rotate(180deg) translateY(50%);
    }
  }

  &__counter {
    margin-top: 10px;
    text-align: center;
    
    @media screen and (min-width: 1024px) {
      bottom: 35px;
      left: 140px;
      margin-top: 0;
      position: absolute;
      text-align: left;
    }    
  }
}
