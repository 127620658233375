.c-link {
  align-items: center;
  color: inherit;
  cursor: pointer;
  display: inline-flex;
  line-height: inherit;
  text-decoration: none;

  &--primary {
    color: $primary;
    text-transform: uppercase;
  }

  &--margin {
    margin-bottom: 2rem;
  }

  &:hover {
    color: inherit;
    text-decoration: underline;
  }

  &__icon {
    display: inline-block;
    fill: currentColor;
    height: 0.8em;
    width: 0.8em;

    &--left {
      margin-right: 0.5em;
    }

    &--right {
      margin-right: 0.5em;
    }
  }
}
