.row {
	padding-right: 10px;
	padding-left: 10px;
	position: relative;

	.row {
		padding-right: 0;
		padding-left: 0;
	}
}

.columns {

	&--white { background: $white; }
	&--iron { background: $iron; }
	&--ghost { background: $ghost; }
	&--primary { background: $primary; }
	&--stripes-iron { @include stripes($iron, "white"); }
}

.visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

/* - - [ Hide touch elements ] - - */

.hide-for-mobile {
	.mobile & { display: none !important; }
}
.hide-for-tablet {
	.tablet & { display: none !important; }
}
.hide-for-desktop {
	.desktop & { display: none !important; }
}