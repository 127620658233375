.c-section-title {
  @include indent-title("large", 2.8rem, $secondary, $secondary);

  font-weight: 400;
  text-align: center;

  @media screen and (min-width: 420px) {
    @include indent-title("large", 3.8rem, $secondary, $secondary);
  }

  &--margin {
    margin-bottom: 20px;

    @media screen and (min-width: 768px) {
      margin-bottom: 40px;
    }
  }

  &--left {
    text-align: left;
  }

  &--bolder {
    font-weight: 700;
  }

  &--white {
    color: $white;
  }

  &--news {
    font-weight: bold;
    text-transform: uppercase;
  }
}
